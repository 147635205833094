<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    submit-title="Find"
    title="Find route"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <gl-input
      v-model="from"
      class="gl-modal__row"
      label="From Wallet"
      name="from"
      rules="required"
    />
    <gl-input
      v-model="to"
      class="gl-modal__row"
      label="To Wallet"
      name="to"
      rules="required"
    />
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'

import {validate} from "vee-validate";

import { mapState } from "vuex";

export default {
  components: {
    GlModal,
    GlInput,
  },
  inheritAttrs: false,
  props: {
    searchMode: {
      type: String,
      default: 'tx',
    },
  },
  data() {
    return {
      from: '',
      to: '',
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'currencyList'])
  },
  methods: {
    async onSubmit() {
      const networkKey = this.currencyList.find(item => item.key === this.coinType).key || 'btc'
      const addressFromValidationResult = await validate(this.from, `address:${ networkKey }`)
      const addressToValidationResult = await validate(this.to, `address:${ networkKey}`)


      if (addressFromValidationResult.valid && addressToValidationResult.valid) {
        this.$emit('find-route', { from: this.from, to: this.to })
        this.$emit('input', false)
      } else {
        this.$toasted.global.error({message: 'Search values not valid'})
      }
    },
  },
}
</script>
