<template>
  <gl-modal
    v-bind="$attrs"
    submit-title="Find"
    full-buttons
    title="Find intersection"
    v-on="$listeners"
    @submit="onSubmit"
  >
    <div
      v-for="(tx, index) in transactions"
      :key="index"
      class="gl-modal__row find-intersection-modal__row"
    >
      <gl-input
        v-model="transactions[index]"
        class="tx-input"
        label="Tx Hash"
        :name="`tx hash ${index + 1}`"
        rules="required|txHash"
      />
      <button
        v-if="index"
        class="gl-button gl-button--danger"
        @click="removeRow(index)"
      >
        -
      </button>
      <button
        v-else
        class="gl-button gl-button--primary"
        @click="addRow"
      >
        +
      </button>
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'

export default {
  components: {
    GlModal,
    GlInput,
  },
  inheritAttrs: false,
  data() {
    return {
      transactions: ['', ''],
      to: '',
    }
  },
  methods: {
    onSubmit() {
      this.$emit('find-intersection', this.transactions)
      this.transactions = ['', '']
      this.$emit('input', false)
    },
    addRow() {
      this.transactions.push('')
    },
    removeRow(index) {
      this.transactions.splice(index, 1)
    },
  },
}
</script>
