<template>
  <div class="toolbar flex align-center">
    <div class="flex m-fullwidth">
      <div class="flex align-center m-flex-nowrap m-fullwidth toolbar__wrap-coin-select">
        <!--        <vSelect-->
        <!--          v-if="hasMultyCoin"-->
        <!--          v-model="coin"-->
        <!--          class="coin-select mr-2"-->
        <!--          :clearable="false"-->
        <!--          label="label"-->
        <!--          :options="currencyList"-->
        <!--          @change="handleCoinSelect"-->
        <!--        />-->
        <GlCoinSelect
          :slim="isMobile ? true : false"
          small
          @change="handleCoinSelect"
        />
        <gl-search-box
          v-model="search"
          class="search-box mr-4 m-fullwidth m-mr-0"
          dark-clear
          :independent="isMobile ? true : false"
          :min-width="isMobile ? '100%' : windowWidth < 1000 ? '' : '450px'"
          placeholder="Add wallet address or transaction"
          @search="searching"
        />
      </div>
      <div class="flex dropdowns__wrap m-display-none view-dropdown__wrap">
        <gl-menu-dropdown
          icon-after="arrow-down"
          :icon-height="24"
          :icon-width="24"
          label="View"
          padder
          @toggle="handleToggleViewMenu"
          class="dropdowns__first-item"
        >
          <!-- <ViewBar
            :address="address"
            :amount="amount"
            :amount-view-mode="amountViewMode"
            :hash="hash"
            :label="label"
            :view-mode="viewMode"
            @address="(e) => address = e"
            @amount="(e) => amount = e"
            @amount-view-mode="(e) => amountViewMode = e"
            @change-amount-view-mode="changeEdgeLabels"
            @change-edge-labels="changeEdgeLabels"
            @change-node-labels="changeNodeLabels"
            @change-view-mode="changeViewMode"
            @hash="(e) => hash = e"
            @label="(e) => label = e"
            @view-mode="(e) => viewMode = e"
          /> -->
          <ViewBarNew
            :address="address"
            :amount="amount"
            :amount-view-mode="amountViewMode"
            :hash="hash"
            :label="label"
            :self-transactions="selfTransactions"
            :view-mode="viewMode"
            @address="(e) => address = e"
            @amount="(e) => amount = e"
            @amount-view-mode="(e) => amountViewMode = e"
            @change-amount-view-mode="changeEdgeLabels"
            @change-edge-labels="changeEdgeLabels"
            @change-node-labels="changeNodeLabels"
            @change-self-transactions="changeSelfTransactions"
            @change-view-mode="changeViewMode"
            @hash="(e) => hash = e"
            @label="(e) => label = e"
            @self-transactions="(e) => selfTransactions = e"
            @view-mode="(e) => viewMode = e"
          />
        </gl-menu-dropdown>
        <gl-menu-dropdown
          ref="casesDropdown"
          icon-after="arrow-down"
          :icon-height="24"
          :icon-width="24"
          label="Case"
          class="dropdowns__second-item"
        >
          <CasesBar
            :case-data="caseData"
            :export-list="exportList"
            :is-unknow-mode="isUnknowMode"
            @export-case-handler="exportCaseHandler('cases')"
            @exports="exports($event)"
            @open-file-input="openFileInput"
            @save-as="saveAs"
          />
        </gl-menu-dropdown>
      </div>
    </div>
    <div v-if="caseData" class="flex ml-3 align-center toolbar-top__case-block">
      <div class="flex align-center mr-2 ellipsis">
        <gl-icon
          class="mr-2"
          :height="24"
          name="cases"
          :width="24"
        />
        <div
          class="ellipsis"
          style="max-width: 250px"
        >
          Case: {{ caseData.title }}
        </div>
      </div>
      <div
        v-tooltip="'Save'"
        class="pointer"
        @click="save"
      >
        <gl-icon
          v-if="caseData"
          class="mr-1"
          :height="24"
          name="save"
          :width="24"
        />
      </div>
      <div
        v-tooltip="'Save as'"
        class="pointer"
        @click="saveAs"
      >
        <gl-icon
          v-if="caseData"
          :height="24"
          name="save-as"
          :width="24"
        />
      </div>
    </div>

    <input
      ref="file"
      accept=".json"
      :style="{display: 'none'}"
      type="file"
      @change="onFileChange"
    >
    <ToolbarActionsWrapper
      v-if="showMobileViewMenu && isMobile"
      title="View"
      @close="handleToggleViewMenu(false)"
    >
      <!-- <ViewBar
        :address="address"
        :amount="amount"
        :amount-view-mode="amountViewMode"
        :hash="hash"
        :label="label"
        :view-mode="viewMode"
        @address="(e) => address = e"
        @amount="(e) => amount = e"
        @amount-view-mode="(e) => amountViewMode = e"
        @change-amount-view-mode="changeEdgeLabels"
        @change-edge-labels="changeEdgeLabels"
        @change-node-labels="changeNodeLabels"
        @change-view-mode="changeViewMode"
        @hash="(e) => hash = e"
        @label="(e) => label = e"
        @view-mode="(e) => viewMode = e"
      /> -->
      <ViewBarNew
        :address="address"
        :amount="amount"
        :amount-view-mode="amountViewMode"
        :hash="hash"
        :label="label"
        :self-transactions="selfTransactions"
        :view-mode="viewMode"
        @address="(e) => address = e"
        @amount="(e) => amount = e"
        @amount-view-mode="(e) => amountViewMode = e"
        @change-amount-view-mode="changeEdgeLabels"
        @change-edge-labels="changeEdgeLabels"
        @change-node-labels="changeNodeLabels"
        @change-view-mode="changeViewMode"
        @hash="(e) => hash = e"
        @label="(e) => label = e"
        @self-transactions="(e) => selfTransactions = e"
        @view-mode="(e) => viewMode = e"
      />
    </ToolbarActionsWrapper>
    <ToolbarActionsWrapper
      v-if="showMobileCasesBar && isMobile"
      title="Case"
      @close="showMobileCasesBar = false"
    >
      <CasesBar
        :case-data="caseData"
        :export-list="exportList"
        :is-unknow-mode="isUnknowMode"
        @export-case-handler="exportCaseHandler('cases')"
        @exports="exports($event)"
        @open-file-input="openFileInput"
        @save-as="saveAs"
      />
    </ToolbarActionsWrapper>
  </div>
</template>

<script>
// Components
import GlMenuDropdown from "@/components/gl-menu-dropdown";
import GlIcon from "@/components/gl-icon";
import GlSearchBox from "@/components/gl-search-box";
import vSelect from 'vue-select';
import GlCoinSelect from "@/components/gl-coin-select.vue";
// import ViewBar from "./components/ViewBar.vue";
import ViewBarNew from "./components/ViewBarNew.vue";
import CasesBar from "./components/CasesBar.vue";
import ToolbarActionsWrapper from "./components/ToolbarActionsWrapper.vue";
// Vuex
import { mapMutations, mapState } from "vuex";
import { validate } from "vee-validate";
import config from "@/utils/appConfig";
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlIcon,
    GlSearchBox,
    GlMenuDropdown,
    GlCoinSelect,
    vSelect,
    // ViewBar,
    ViewBarNew,
    CasesBar,
    ToolbarActionsWrapper
    
  },
  mixins: [deviceWidthMixin],
  props: {
    searchMode: {
      type: String,
      default: 'tx',
    },
    showCyto: {
      type: Boolean,
      default: false,
    },
    selectedElement: {
      type: Object,
      default: () => ({})
    },
    caseData: {
      type: Object,
      default: () => ({})
    },
    cytoscape: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      label: true,
      address: false,
      amount: false,
      hash: false,
      calendarOpen: false,
      viewMode: 'address',
      selfTransactions: true,
      amountViewMode: 'tokens',
      search: '',
      coin: {
        key: 'btc',
        path: 'tx',
        label: 'BTC'
      },
      exportList: {
        label: "Export",
        icon: 'export',
        iconHeight: 24,
        iconWidth: 24,
        hasParent: false,
        disabled: this.isUnknowMode,
        children: [
          { name: "png", label: 'Export as PNG', icon: "png", hasParent: true, },
          { name: "csv", label: 'Export as CSV', icon: "png", hasParent: true, },
          { name: "json", label: 'Export as JSON', icon: "json", hasParent: true, },
        ]
      },
      showMobileViewMenu: false,
      showMobileCasesBar: false,
    }
  },
  computed: {
    ...mapState('analytics', ['isHash', 'isAddress', 'canUndo', 'canRedo', 'coinType', 'searchType', 'currencyList', 'coinData']),
    isUnknowMode() {
      return !this.isHash && !this.isAddress
    },
    hasMultyCoin() {
      return config.VUE_APP_COIN_TYPE
    },
    isSelfTransactionsFeatureFlag() {
      return config.VUE_APP_FEATURE_SELF_TRANSACTIONS
    },
    modeExplain() {
      if (this.isAddress) {
        return 'Search for a wallet address and click on the address nodes to investigate all connected transactions'
      } else if (this.isHash) {
        return 'Search for a TX hash and click on the input/output address node to reveal the prev/next spend'
      }

      return 'Indicator of the node click-to-explore mode. Search for a transaction ID so by clicking on the node, you reveal the next spend of the output. Search for a wallet address to investigate all of its transactions.'
    },
    getModeLabel() {
      if (this.isAddress) {
        return 'Exploration'
      } else if (this.isHash) {
        return 'Tracing'
      }

      return 'Unknown'
    },
  },
  watch: {
    coinType: {
      handler(val) {
        const currency = this.currencyList.find(el => el.key === val)

        if (currency) {
          this.coin = currency
          this.SET_COIN_TYPE(currency.key)
          this.SET_COIN_DATA(currency)
        } else {
          this.SET_COIN_TYPE('tx')
          this.coin = {
            key: 'btc',
            path: 'tx',
            label: 'BTC'
          }
        }
      },
      immediate: true
    },
    $route: {
      async handler(val, from) {
        if ((!val || !val.name || val.name === 'analytics') && (!from || !from.name || from.name === 'analytics')) {
          if (val.query.type) {
            const currency = this.currencyList.find(el => el.key === val.query.type)

            if (currency) {
              this.coin = currency
              this.SET_COIN_TYPE(currency.key)
              this.SET_COIN_DATA(currency)
            } else {
              this.SET_COIN_TYPE('tx')
              this.coin = {
                key: 'btc',
                path: 'tx',
                label: 'BTC'
              }
            }
          }

          if (val.query.tx) {
            this.search = val.query.tx;
            const hashValidationResult = await validate(this.search, 'txHash', {name: 'Search value'})
            if (this.search && hashValidationResult.valid) {
              this.SET_VALIDATE_HASH(true)
              this.SET_VALIDATE_ADDRESS(false)
            }
          }

          if (val.query.address) {
            this.search = val.query.address;
            const addressValidationResult = await validate(this.search, 'address:btc', { name: 'Search value' })
            if (this.search && addressValidationResult.valid) {
              this.SET_VALIDATE_HASH(false)
              this.SET_VALIDATE_ADDRESS(true)
            }
          }
        }

        if (!val.query.address && !val.query.tx && !this.searchValue) {
          this.search = ''
          this.SET_VALIDATE_HASH(false)
          this.SET_VALIDATE_ADDRESS(false)
        }

        if (this.cytoscape && this.cytoscape.search) {
          this.search = this.cytoscape.search
        }
      },
      immediate: true,
    },
    '$store.state.analytics.searchValue': 'setSearchValue',
  },
  created() {
    this.setParams()
    this.$root.$on('search', this.setParams);
    if (this.isMobile) {
      this.$root.$on('toggle-view-menu', (val) => {
        this.handleToggleViewMenu(val)
      })
      this.$root.$on('toggle-cases-menu', (val) => {
        this.showMobileCasesBar = val
      })
    }
  },
  beforeDestroy() {
    this.$root.$off("search");
    if (this.isMobile) {
      this.$root.$off("toggle-view-menu");
      this.$root.$off("toggle-cases-menu");
    }
  },
  methods: {
    ...mapMutations({
      SET_STEPPED_STATE: 'analytics/SET_STEPPED_STATE',
      SET_VALIDATE_HASH: 'analytics/SET_VALIDATE_HASH',
      SET_VALIDATE_ADDRESS: 'analytics/SET_VALIDATE_ADDRESS',
      SET_SEARCH_VALUE: 'analytics/SET_SEARCH_VALUE',
      SET_SEARCH_TYPE: 'analytics/SET_SEARCH_TYPE',
      SET_COIN_TYPE: 'analytics/SET_COIN_TYPE',
      SET_COIN_DATA: 'analytics/SET_COIN_DATA',
    }),
    handleCoinSelect(data) {
      this.SET_COIN_TYPE(data.key)
      this.SET_COIN_DATA(data)
    },
    handleToggleViewMenu(val) {
      this.showMobileViewMenu = val
      if (val) {

        const { label, address } = JSON.parse(localStorage.getItem('label-view'))

        if (label && address) {
          this.label = label
          this.address = address
        }

        const { amount, hash } = JSON.parse(localStorage.getItem('edge-view'))

        if (amount && hash) {
          this.amount = amount
          this.hash = hash
        }

        this.checkSelfTransactions()

        this.cytoscape.cy.nodes().find(el => el.data('type') === 'cluster')
          ? this.viewMode = 'cluster'
          : this.viewMode = 'address'
      }
    },
    setSearchValue(val) {
      this.search = val
    },
    async searching(value) {
      const valid = new RegExp(this.coinData.addressRegex).test(value) || new RegExp(this.coinData.txRegex).test(value)

      if (!valid) {
        this.$toasted.global.error({ message: 'Search value is not valid'})
        return
      }
      if (value) {
        this.SET_SEARCH_VALUE(value)
      }

      if (value) {
        this.SET_STEPPED_STATE({ undo: false, redo: false })

        if (value.substring(0, 2) === '0x') {
          if (value.length < 60) {
            this.$root.$emit('search');
            this.SET_VALIDATE_HASH(false)
            this.SET_VALIDATE_ADDRESS(true)
            this.SET_SEARCH_TYPE(this.coinType)
            //TODO remove this
            await this.$router.push({ name: 'analytics', query: { type: this.coinType, address: value, 'search-id': (Math.random() * 1000).toFixed(0).toString() } })
            return
          } else {
            this.$root.$emit('search');
            this.SET_VALIDATE_HASH(true)
            this.SET_VALIDATE_ADDRESS(false)
            this.SET_SEARCH_TYPE(this.coinType)
            //TODO remove this
            await this.$router.push({
              name: 'analytics',
              query: {type: this.coinType, tx: value, 'search-id': (Math.random() * 1000).toFixed(0).toString()}
            })
            return
          }
        }

        const hashValidationResult = await validate(value, 'txHash', { name: 'Search value' })
        if (hashValidationResult.valid) {
          this.$root.$emit('search');
          this.SET_VALIDATE_HASH(true)
          this.SET_VALIDATE_ADDRESS(false)
          this.SET_SEARCH_TYPE('tx')
          //TODO remove this
          await this.$router.push({ name: 'analytics', query: { type: this.coinType, tx: value, 'search-id': (Math.random() * 1000).toFixed(0).toString() } })
          return
        }
        // const addressValidationResult = await validate(value, 'address:btc', { name: 'Search value' })
        // if (addressValidationResult.valid) {
          this.$root.$emit('search');
          this.SET_VALIDATE_HASH(false)
          this.SET_VALIDATE_ADDRESS(true)
          this.SET_SEARCH_TYPE('address')
          await this.$router.replace({ name: 'analytics', query: { type: this.coinData.key, address: value, 'search-id': (Math.random() * 1000).toFixed(0).toString() } })
          return
        // }
        // this.$toasted.global.error({ message: 'Search value is not valid'})

        // this.SET_VALIDATE_HASH(false)
        // this.SET_VALIDATE_ADDRESS(false)
      }
    },
    clearValue() {
      this.SET_VALIDATE_HASH(false)
      this.SET_VALIDATE_ADDRESS(false)
      this.$router.replace('analytics')
      localStorage.removeItem('graph')
    },
    provide(val) {
      this.$emit('set-transaction', val)
    },
    provideHide(val) {
      this.$emit('search-and-hide-elements', val)
    },
    provideTx(val) {
      this.$emit('add-tx-data', val)
    },
    setParams() {
      const localViewMode = localStorage.getItem('view-mode')

      localViewMode ? this.viewMode = localViewMode : 'address'

      this.changeViewMode(this.viewMode)

      const localLabelView = JSON.parse(localStorage.getItem('label-view'))

      if (localLabelView) {
        this.address = localLabelView.address
        this.label = localLabelView.label
      } else {
        this.address = false
        this.label = true
      }

      this.changeNodeLabels()

      const localEdgeView = JSON.parse(localStorage.getItem('edge-view'))

      if (localEdgeView) {
        this.amount = localEdgeView.amount
        this.hash = localEdgeView.hash
        this.amountViewMode = localEdgeView.amountViewMode
      } else {
        this.amount = false
        this.hash = true
        this.amountViewMode = 'tokens'
      }

      this.checkSelfTransactions()

      this.changeEdgeLabels()
    },
    exportCaseHandler($event) {
      if (this.caseData) {
        this.save($event)
      } else {
        this.exports($event)
      }

      this.$refs.casesDropdown.isOpen = false
      this.showMobileCasesBar = false
    },
    exports($event) {
      this.$emit('export', $event)
    },
    save() {
      this.$emit('save', false)
    },
    saveAs() {
      this.$emit('save-as', 'cases')
    },
    changeViewMode(val) {
      this.$emit('change-view-mode', val)
      localStorage.setItem('view-mode', val)
    },
    changeSelfTransactions() {
      this.$emit('change-self-transactions', this.selfTransactions)
      localStorage.setItem('self-transactions', JSON.stringify({ selfTransactions: this.selfTransactions }))
    },
    changeNodeLabels() {
      this.$emit('change-node-labels', { label: this.label, address: this.address })
      localStorage.setItem('label-view', JSON.stringify({ label: this.label, address: this.address }))
    },
    changeEdgeLabels() {
      this.$emit('change-edge-labels', { amount: this.amount, hash: this.hash, amountViewMode: this.amountViewMode  })
      localStorage.setItem('edge-view', JSON.stringify({ amount: this.amount, hash: this.hash, amountViewMode: this.amountViewMode  }))
    },
    openFileInput() {
      this.$refs.file.click()
    },
    onFileChange(e) {
      const [file] = e.target.files
      e.target.value = ''

      if (file) this.$emit('import', file)
    },
    checkSelfTransactions() {
      const localSelfTransactions = JSON.parse(localStorage.getItem('self-transactions'))

      if (localSelfTransactions && this.isSelfTransactionsFeatureFlag) {
        this.selfTransactions = localSelfTransactions.selfTransactions
      } else {
        this.selfTransactions = true
      }
    }
  },
}
</script>

<style scoped>
.dropdowns__wrap {
  background: var(--white);
  border-radius: 3px;
  height: 32px;
}

.dropdowns__first-item,
.dropdowns__second-item {
  display: flex;
  align-items: center;
  width: 86px;
  padding: 6px 4px;
  position: relative;
}
.dropdowns__first-item svg,
.dropdowns__second-item svg {
  transition: transform 0.3s ease;
}
.dropdowns__second-item {
  padding-left: 14px;
}

.dropdowns__wrap::v-deep .dropdowns__first-item .gl-menu-dropdown,
.dropdowns__wrap::v-deep .dropdowns__second-item .gl-menu-dropdown {
  width: 172px;
  margin-top: 4px;
  left: 0;
  top: 100%;
}

.dropdowns__wrap::v-deep .dropdowns__second-item .gl-menu-dropdown {
  left: -86px;
}
.dropdowns__wrap::v-deep .gl-menu-button--open svg.gl-icon--arrow-down {
  transform: rotate(-180deg);
}

.dropdowns__wrap::v-deep .menu-dropdown-items-wrapper {
  font-size: 14px;
}
.dropdowns__wrap::v-deep .gl-checkbox__input:checked + .gl-checkbox__check:after {
  width: 8px;
  height: 8px;
  left: 2px;
  top: 2px;
}
.dropdowns__wrap::v-deep .gl-radio__check {
  width: 14px;
  height: 14px;
}
.dropdowns__wrap::v-deep .gl-radio__input:checked + .gl-radio__check:after {
  width: 8px;
  height: 8px;
}
.dropdowns__wrap::v-deep .child {
  width: calc(100% + 32px);
  min-width: 100%;
  margin-left: -16px;
  padding: 8px 16px 8px 24px;
}

.coin-select .vs__dropdown-toggle{
  background: #fff;
  border: 1px solid #f5f5f5 !important;
  /*height: 35px !important;*/
  min-height: 30px;
  width: 102px;
}

.toolbar__wrap-coin-select::v-deep .v-select .vs__dropdown-toggle {
  padding: 0 0 0 8px !important;
  border-radius: 3px 0 0 3px;
}
.toolbar__wrap-coin-select::v-deep .v-select .vs__dropdown-toggle .vs__selected-options {
  padding-left: 0;
}
.toolbar__wrap-coin-select::v-deep .v-select .vs__dropdown-toggle .vs__selected-options .vs__selected {
  margin-left: 0;
  padding-left: 0;
  padding-top: 2px;
  position: absolute;
}
.toolbar__wrap-coin-select::v-deep .coin-select-base .vs__dropdown-toggle {
  min-width: 72px !important;
}
.toolbar__wrap-coin-select::v-deep .v-select.mr-2 {
  margin-right: 0 !important;
}
.toolbar__wrap-coin-select::v-deep .gl-search-box__input {
  min-width: 292px !important;
  border-radius: 0 3px 3px 0;
}
.toolbar__wrap-coin-select::v-deep .gl-button {
  min-width: 93px;
  width: 93px;
}
.toolbar__wrap-coin-select::v-deep .vs__dropdown-menu {
  min-width: 72px;
}
.toolbar__wrap-coin-select::v-deep .gl-search-box.mr-4 {
  margin-right: 16px !important;
}

.toolbar-top__case-block {
  height: 32px;
  padding: 0 10px;
  background-color: #fff;
  border-radius: 3px;
}


@media (max-width: 767px) {
  .search-box {
    border-left: 2px solid var(--dark-grey-d-3);
  }
  .toolbar__wrap-coin-select {
    background-color: var(--cotton-grey-f-5);
    border-radius: 4px;
  }
  .toolbar__wrap-coin-select::v-deep .gl-search-box__input,
  .toolbar__wrap-coin-select::v-deep .v-select,
  .toolbar__wrap-coin-select::v-deep .coin-select-base .vs__dropdown-toggle {
    background-color: transparent;
  }
  .toolbar__wrap-coin-select::v-deep .gl-search-box__input {
    min-width: 100% !important;
  }
}

.view-dropdown__wrap::v-deep .gl-menu-dropdown__padder {
  width: 260px !important;
  padding: 0 0 8px 0;
  cursor: default;
}
.view-dropdown__wrap::v-deep .gl-select-button-wrapper {
  width: 100%;
}
.view-dropdown__wrap::v-deep .gl-select-button__item {
  display: flex;
  flex: 1 0 50%;
}
</style>
