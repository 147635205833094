<template>
  <div class="view-dropdown">
    <div 
      v-if="!$can('use', 'eth')" 
      class="view-dropdown__block"
    >
      <div class="view-dropdown__block-header">
        View
      </div>
      <div class="view-dropdown__block-content">
        <div class="view-dropdown__block-item view-dropdown__block-item--no-hover">
          <GlSelectButton
            grey
            :options="viewOptions"
            :value="localViewMode"
            @input="viewChange($event)"
          />
        </div>
      </div>
    </div>
    <div class="view-dropdown__block">
      <div class="view-dropdown__block-content">
        <div 
          class="view-dropdown__block-item" 
          @click.prevent="changeNodeLabels('localAddress')"
        >
          <div class="view-dropdown__block-item-left">
            <div class="view-dropdown__block-item-name view-dropdown__block-text--accent">
              Address
            </div>
          </div>
          <div class="view-dropdown__block-item-right">
            <o-switch
              v-model="localAddress"
            />
          </div>
        </div>
        <div 
          class="view-dropdown__block-item" 
          @click.prevent="changeNodeLabels('localLabel')"
        >
          <div class="view-dropdown__block-item-left">
            <div 
              v-if="$can('use', 'btc')"
              class="view-dropdown__block-item-name"
            >
              Cluster / Entity
            </div>
            <div 
              v-if="$can('use', 'eth')"
              class="view-dropdown__block-item-name"
            >
              Entity
            </div>
          </div>
          <div class="view-dropdown__block-item-right">
            <o-switch
              v-model="localLabel"
            />
          </div>
        </div>
        <div
          v-if="isSelfTransactionsFeatureFlag"
          class="view-dropdown__block-item" 
          @click.prevent="selfTransactionsClick()"
        >
          <div class="view-dropdown__block-item-left">
            <div class="view-dropdown__block-item-name">
              Self transactions
            </div>
          </div>
          <div class="view-dropdown__block-item-right">
            <o-switch
              v-model="localSelfTransactions"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="view-dropdown__block view-dropdown__block--other">
      <div class="view-dropdown__block-header view-dropdown__block-header--small">
        Transaction
      </div>
      <div class="view-dropdown__block-content">
        <div
          class="view-dropdown__block-item" 
          @click.prevent="changeEdgeLabels('localHash')"
        >
          <div class="view-dropdown__block-item-left">
            <div class="view-dropdown__block-item-name">
              Hash
            </div>
          </div>
          <div class="view-dropdown__block-item-right">
            <o-switch
              v-model="localHash"
            />
          </div>
        </div>
        <div
          class="view-dropdown__block-item" 
          @click.prevent="changeEdgeLabels('localAmount')"
        >
          <div class="view-dropdown__block-item-left">
            <div class="view-dropdown__block-item-name">
              Amount
            </div>
          </div>
          <div class="view-dropdown__block-item-right">
            <o-switch
              v-model="localAmount"
            />
          </div>
        </div>
      </div>
    </div>
    <div 
      v-if="localAmount"
      class="view-dropdown__block-sub"
    >
      <div
        class="view-dropdown__block-item" 
        @click="radioSwitch('tokens')"
      >
        <gl-radio
          v-model="localAmountViewMode"
          label="Token"
          value="tokens"
        />
      </div>
      <div
        class="view-dropdown__block-item" 
        @click="radioSwitch('fiat')"
      >
        <gl-radio
          v-model="localAmountViewMode"
          :label="`Fiat ${fiat.label}`"
          value="fiat"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlSelectButton from '@/components/gl-select-button'
import GlRadio from '@/components/gl-radio-button'
// Utils
import { findFiatByKey } from "@/utils/format-by-price"
import config from '@/utils/appConfig'
// Vuex
import { mapState } from 'vuex'

export default {
  components: {
    GlRadio,
    GlSelectButton,
  },
  props: {
    viewMode: {
      type: String,
      default: 'address',
    },
    amountViewMode: {
      type: String,
      default: 'tokens',
    },
    label: {
      type: Boolean,
      default: true,
    },
    address: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Boolean,
      default: false,
    },
    hash: {
      type: Boolean,
      default: false,
    },
    selfTransactions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fiat: '',
      viewOptions: [
        { name: 'Addresses', value: 'address' },
        { name: 'Clusters', value: 'cluster' },
      ],
    }
  },
  computed: {
    ...mapState('user', ['userData', 'fiatList']),
    isSelfTransactionsFeatureFlag() {
      return config.VUE_APP_FEATURE_SELF_TRANSACTIONS
    },
    localViewMode: {
      get: function () {
        return this.viewOptions.find(option => option.value === this.viewMode)
      },
      set: function (e) {
        this.$emit('view-mode', e)
      },
    },
    localAmountViewMode: {
      get: function () {
        return this.amountViewMode
      },
      set: function (e) {
        this.$emit('amount-view-mode', e)
      },
    },
    localLabel: {
      get: function () {
        return this.label
      },
      set: function (e) {
        this.$emit('label', e)
      },
    },
    localAddress: {
      get: function () {
        return this.address
      },
      set: function (e) {
        this.$emit('address', e)
      },
    },
    localAmount: {
      get: function () {
        return this.amount
      },
      set: function (e) {
        this.$emit('amount', e)
      },
    },
    localHash: {
      get: function () {
        return this.hash
      },
      set: function (e) {
        this.$emit('hash', e)
      },
    },
    localSelfTransactions: {
      get: function () {
        return this.selfTransactions
      },
      set: function (e) {
        this.$emit('self-transactions', e)
      },
    },
  },
  mounted() {
    this.fiat = findFiatByKey(
      this.userData.preferredCurrency || this.fiatList[0].key,
    )
  },
  methods: {
    viewChange({ value }) {
      this.localViewMode = value
      this.$emit('change-view-mode', value)
    },
    changeNodeLabels(key) {
      this[key] = !this[key]
      this.$emit('change-node-labels')
    },
    changeEdgeLabels(key) {
      this[key] = !this[key]
      this.$emit('change-edge-labels')
    },
    selfTransactionsClick() {
      this.localSelfTransactions = !this.localSelfTransactions
      this.$emit('change-self-transactions')
    },
    radioSwitch(value) {
      this.localAmountViewMode = value
      this.$emit('change-amount-view-mode', value)
    },
  },
}
</script>

<style>
.view-dropdown {
  user-select: none;
}
.view-dropdown__block {
  padding: 12px;
}
.view-dropdown__block:first-child {
  padding-top: 16px;
}
.view-dropdown__block:last-child {
  padding-bottom: 0;
}
.view-dropdown__block-sub {
  margin-top: -12px;
  padding: 0 12px;
}
.view-dropdown__block-sub .view-dropdown__block-item {
  padding-left: 27px;
}
.view-dropdown__block + .view-dropdown__block {
  border-top: 1px solid var(--menu-border);
}
.view-dropdown__block-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.view-dropdown__block-header--small {
  font-size: 16px;
  line-height: 32px;
}
.view-dropdown__block-header + .view-dropdown__block-content {
  margin-top: 6px;
}
.view-dropdown__block--other .view-dropdown__block-header + .view-dropdown__block-content {
  margin-top: 0;
}
.view-dropdown__block-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  margin-left: -12px;
  margin-right: -12px;
  padding: 0 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.view-dropdown__block-item:hover:not(.view-dropdown__block-item--no-hover) {
  background-color: var(--menu-background);
}
.view-dropdown__block-item-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--space-cadet);
}
.view-dropdown__block-text--accent {
  font-weight: 600;
  font-size: 16px;
}
.view-dropdown__block-item .gl-radio {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--space-cadet);
}
.view-dropdown__block-item .gl-radio:hover .gl-radio__check {
  border: 1px solid var(--light-grey-blue);
}
.view-dropdown__block-item .gl-radio.gl-radio--checked:hover .gl-radio__check {
  border: 1px solid var(--soft-blue);
}
.view-dropdown__block-item-right {
  display: flex;
  align-items: center;
}
.view-dropdown__block-item .o-switch__label {
  display: none;
}
.view-dropdown__block-item .gl-select-button-wrapper {
  margin-bottom: 3px;
}
</style>