<template>
  <div>
    <div class="px-3 bold-600 cluster-address-table__header">
      Cluster Addresses
    </div>
    <div class="table-wrap table-wrap__unoverflow gl-table-unshadow px-3 cluster-addresses-table">
      <o-table
        backend-sorting
        class="statistic-table stats-custom-table table__overflow-auto cluster-address-table"
        :class="{ 'o-table-has-pagination': data.length && totalPages > 1 }"
        :data="data"
        :default-sort="[sortField, sortDirection]"
        :default-sort-direction="defaultSortOrder"
        hoverable
        :loading="loading"
        :mobile-cards="false"
        sort-icon="chevron-up"
        @sort="onSort"
      >
        <template v-for="column in columns">
          <o-table-column
            :key="column.id"
            v-bind="column"
          >
            <template v-slot:header>
              <div class="flex align-center">
                <div>
                  {{ column.label }}
                </div>
                <gl-icon
                  v-if="column.sortable"
                  class="ml-1"
                  :height="24"
                  name="add-sorting"
                  :width="24"
                />
              </div>
            </template>
            <template v-slot="props">
              <div v-if="column.field === 'address'">
                <div
                  v-popover:tooltip.top="`${props.row.address}`"
                  class="link"
                  @click="openInNewTabAddress(props.row.address)"
                >
                  {{ trancateString(props.row.address, 8) }}
                </div>
              </div>
              <div v-if="column.field === 'actions'">
                <div class="link">
                  <AddressTxActions
                    add-data-to-graph
                    :address="props.row.address"
                    @add-tx-data="$emit('add-tx-data', $event)"
                  />
                </div>
              </div>
              <div v-if="column.field === 'txCount'">
                <div
                  class="capitalize ellipsis"
                  style="min-width: 60px"
                >
                  {{ props.row.txCount }}
                </div>
              </div>
              <div v-if="column.field === 'balance'">
                <div
                  class="flex column align-end capitalize ellipsis"
                >
                  <div>
                    {{ props.row.balance ? toComaSeparate(formatBtcAmount(props.row.balance, true, '', false)) :
                      props.row.balance == 0 ? 0 : '-' }}
                  </div>
                  <div
                    v-if="props.row.price"
                    class="fw-400 fs-13 ml-2"
                  >
                    {{ formatByPrice(props.row.price) }}
                    <gl-icon
                      v-popover:tooltip.top="`${priceMessage('current', props.row.priceTimestamp)}`"
                      class="price-info-icon"
                      :height="12"
                      name="info"
                      :width="12"
                    />
                  </div>
                </div>
              </div>
              <div v-if="column.field === 'lastSeen'">
                <div class="min-w-80 max-w-80">
                  {{ props.row.lastSeen ? formatDate(props.row.lastSeen * 1000, 'dd.MM.yyyy HH:mm') : '-' }}
                </div>
              </div>
            </template>
          </o-table-column>
        </template>
        <template #empty>
          <div
            v-if="loading"
            class="flex align-center justify-center"
          >
            <gl-loader />
          </div>
          <div
            v-else
            class="empty-users-data flex column align-center"
          >
            <gl-icon
              :height="24"
              name="statistic"
              :width="24"
            />
            No data here yet
          </div>
        </template>
      </o-table>
      <div class="flex flex-end pa-1">
        <o-pagination
          v-if="data.length"
          class="stat-pagination"
          :current.sync="currentPage"
          order="right"
          :per-page="perPage"
          simple
          :total="total"
          @change="pageChange"
        >
          <o-pagination-button
            slot="previous"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page mr-2"
              :disabled="props.page.disabled"
              icon="left"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>

          <o-pagination-button
            slot="next"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page"
              :disabled="props.page.disabled"
              icon="right"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>
        </o-pagination>
      </div>
    </div>
  </div>
</template>

<script>
//Vuex
import {mapActions, mapState} from "vuex";
// Components
import GlMenuItem from '@/components/gl-menu-item'
import GlLoader from '@/components/gl-loader'
import GlIcon from '@/components/gl-icon'
import vSelect from 'vue-select'
import AddressTxActions from './addressTxActions';
// Utils
import { capitalizeFirstLetter, trancateString } from "@/utils/text-formatter";
import { formatBtcAmount } from '@/utils/format-btc-amount'
import { formatDate } from "@/utils/format-date";
import { toComaSeparate } from "@/utils/formatNumber";
import { formatByPrice, priceMessage } from "@/utils/format-by-price";

export default {
  name: 'ClusterAddressesTable',
  components: {
    GlMenuItem,
    GlIcon,
    vSelect,
    GlLoader,
    AddressTxActions
  },
  props: {
    cluster: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      pagesOptions: [5, 10, 20, 50],
      currentPage: 1,
      perPage: 20,
      totalPages: 1,
      total: 1,
      score: 0,
      data: [],
      // defAddrOrder: 'any',
      defaultSortOrder: 'desc',
      sortField: '',
      sortDirection: '',
      columns: [
        {
          field: 'address',
          label: 'Address',
          width: 80
        },
        {
          field: 'actions',
          label: 'Actions',
          width: 50
        },
        {
          field: 'txCount',
          label: 'Txs',
          sortable: true,
          width: 150
        },
        {
          field: 'balance',
          label: 'Balance',
          sortable: true,
        },
        {
          field: 'lastSeen',
          label: 'Last activity',
          sortable: true,
        },
      ]
    }
  },
  computed: {
    ...mapState('analytics', ['coinData'])
  },
  watch: {
    cluster: {
      handler(cluster) {
        if (cluster) {
          this.cluster = cluster
          this.currentPage = 1
          this.loadData()
        }
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('analytics', ['getClusterAddresses']),
    capitalizeFirstLetter,
    trancateString,
    formatBtcAmount,
    formatDate,
    toComaSeparate,
    formatByPrice,
    priceMessage,
    formattedSendData() {
      return {
        limit: this.perPage,
        cluster: this.cluster,
        skip: (this.currentPage - 1) * this.perPage,
        sortField: this.sortField || undefined,
        sortDirection: (this.sortDirection === 'desc' ? 1 : -1) || undefined,
      }
    },
    openInNewTabAddress(address) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { address, type: this.coinData.key } })
      window.open(href, '_blank')
    },
    async loadData() {
      if (this.cluster) {
        this.loading = true
        const sendParams = this.formattedSendData()
        const { data: { addressCount, addresses } } = await this.getClusterAddresses(sendParams)
        this.data = addresses
        this.total = addressCount
        this.totalPages = Math.ceil(this.total / this.perPage)
        this.loading = false
      }
    },
    countChange() {
      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
    },
    onSort(field, order) {
      this.sortDirection = order
      this.sortField = field
      this.loadData()
    },
  }
}
</script>

<style>
.cluster-addresses-table {
  font-size: 14px !important;
}
</style>
