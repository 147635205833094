<template>
  <gl-modal
    v-bind="$attrs"
    submit-title="Create"
    title="Create Case"
    v-on="$listeners"
    @close="$emit('close'), value = ''"
    @submit="onSubmit"
  >
    <gl-input
      v-model="value"
      class="gl-modal__row"
      label="Case title"
      name="value"
      :rules="rules"
    />
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'

export default {
  components: {
    GlModal,
    GlInput,
  },
  inheritAttrs: false,
  props: {
    searchMode: {
      type: String,
      default: 'tx',
    },
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: '',
    }
  },
  mounted() {
    if (this.search) {
      this.value = `Case_${this.search}`
    }
  },
  computed: {
    rules() {
      return `required`
    },
  },
  methods: {
    onSubmit() {
      this.$emit('create', this.value)
    },
    resetValue() {
      this.value = ''
    },
  },
}
</script>
