<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    closable
    full-submit
    ok-only
    submit-title="Got it"
    title="Upload Failed"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('close')"
  >
    <div class="mb-4">
      Invalid JSON format
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlMenuItem from '@/components/gl-menu-item'

export default {
  components: {
    GlModal,
    GlMenuItem,
  },
  inheritAttrs: false,
}
</script>