// export const colors = {
//   'Payment providers': '#00a8a8',
//   Exchange: '#54c3c4',
//   Custody: '#4ae2e2',
//   'ICO/Fundraising': '#364fae',
//   Donations: '#4854e0',
//   OTC: '#5a78ea',
//   Mining: '#6ab0eb',
//   'Terrorist financing': '#870041',
//   'Sanctions lists': '#c41551',
//   Ransomware: '#cd1515',
//   'Ponzi scheme': '#e32727',
//   Hack: '#c10572',
//   'Hacker wallet': '#f93276',
//   Malware: '#fb6698',
//   'Darkweb marketplace': '#fa5653',
//   Gambling: '#ff7d54',
//   Other: '#a2a8c1'
// }
export const colors = {
  "binary options": '#a2a8c1',
  "cloud mining": '#6ab0eb',
  "custodian": '#4ae2e2',
  "cyber crime":  '#870041',
  "darkweb transactions": '#fa5653',
  "exchange": '#54c3c4',
  "exchange hack": '#c10572',
  "explorer": '#5a78ea',
  "faucet": '#a2a8c1',
  "fraud": '#f93276',
  "gambling":'#ff7d54',
  "gambpling": '#ff7d54',
  "gold trading": '#5a78ea',
  "hacked exchange": '#c10572',
  "hacked wallet": '#c10572',
  "hardware": '#5a78ea',
  "in-game assets trading": '#5a78ea',
  "investing": '#364fae',
  "lending": '#5a78ea',
  "lending/exchange": '#54c3c4',
  "mining pool": '#6ab0eb',
  "mixing": '#fb6698',
  "mixing service": '#fb6698',
  "mixing service, scam": '#fb6698',
  "non profit": '#15c3c4',
  "p2p exchange": '#4854e0',
  "p2p lending": '#4854e0',
  "payment provider": '#00a8a8',
  "payments / shopping": '#00a8a8',
  "ponzi": '#e32727',
  "ponzi scheme": '#e32727',
  "ransomware": '#cd1515',
  "scam": '#fb6698',
  "scam alert": '#fb6698',
  "service": '#00a8a8',
  "suspended exchange": '#c10572',
  "suspended service": '#00a8a8',
  "trusted exchange": '#54c3c4',
  "wallet": '#15c3c4',
  "default": '#15c3c4',
}

export const ethTypesColors = {
  'DEFAULT': '#15c3c4',
  'call': '#00FF00',
  'delegatecall': '#00FF00',
  'staticcall': '#0a1b64',
  'log': '#848cc7',
  'internal': '#60af57',
  'CREATE': '#4854e0',
  'transfer': '#37b268',
  'balanceOf': '#0e889d',
}
