<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0613 10.0001L15.7801 5.28108C16.0733 4.98814 16.0733 4.51283 15.7801 4.21989C15.4869 3.9267 15.0122 3.9267 14.719 4.21989L10.0001 8.93894L5.28102 4.21989C4.98785 3.9267 4.51306 3.9267 4.21988 4.21989C3.92671 4.51283 3.92671 4.98814 4.21988 5.28108L8.93898 10.0001L4.21988 14.7192C3.92671 15.0121 3.92671 15.4874 4.21988 15.7804C4.36647 15.9267 4.55859 16 4.75045 16C4.94232 16 5.13444 15.9267 5.28102 15.7801L10.0001 11.0611L14.719 15.7801C14.8656 15.9267 15.0577 16 15.2495 16C15.4414 16 15.6335 15.9267 15.7801 15.7801C16.0733 15.4872 16.0733 15.0119 15.7801 14.7189L11.0613 10.0001Z"
      :fill="`var(${color})`"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '--datepicker-blue',
    }
  }
}
</script>