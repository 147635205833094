import model from '~/assets/js/model';

export default model({
  incomingData: {
    ownerGrouping: [],
    typeGrouping: [],
    maxShare: 0,
    sources: [],
    currencies: {}
  },
  outgoingData: {
    ownerGrouping: [],
    typeGrouping: [],
    maxShare: 0,
    sources: [],
    currencies: {}
  }
});
