<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.12149 18H15.2859C16.4563 18 17.4074 17.0489 17.4074 15.8785V5.30665C17.4074 4.13628 16.4563 3.18516 15.2859 3.18516H14.4445V2.59258C14.4445 2.26665 14.1778 2 13.8518 2C13.5259 2 13.2592 2.26665 13.2592 2.59258V3.18516H6.14817V2.59258C6.14817 2.26665 5.88147 2 5.55554 2C5.22961 2 4.96296 2.26665 4.96296 2.59258V3.18516H4.12149C2.95112 3.18516 2 4.13628 2 5.30665V15.8785C2 17.0489 2.95112 18 4.12149 18ZM3.18521 5.30665C3.18521 4.79111 3.60595 4.37038 4.12149 4.37038H4.96296V4.96296C4.96296 5.28889 5.22961 5.55554 5.55554 5.55554C5.88147 5.55554 6.14812 5.28889 6.14812 4.96296V4.37038H13.2592V4.96296C13.2592 5.28889 13.5259 5.55554 13.8518 5.55554C14.1778 5.55554 14.4444 5.28889 14.4444 4.96296V4.37038H15.2859C15.8014 4.37038 16.2222 4.79111 16.2222 5.30665V7.03704H3.18521V5.30665ZM3.18521 8.22221H16.2223V15.8785C16.2223 16.3941 15.8015 16.8148 15.286 16.8148H4.12149C3.60595 16.8148 3.18521 16.3941 3.18521 15.8785V8.22221Z"
      :fill="`var(${color})`"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '--space-cadet',
    }
  }
}
</script>