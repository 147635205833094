import state from "@/store/modules/analytics/state";
export const linkDataFormatter = (val, coin, type = 'address') => {
    const explorerData = state.currencyList.find(el => el.key === coin)

    if (coin === 'trx' && type === 'tx') {
        type = 'transaction'
    }

    return {
        label: explorerData.explorerTitle,
        link: type === 'tx' || type === 'transaction' ? explorerData.explorerTx.replace("{VALUE}", val).replace("{TYPE}", type) : explorerData.explorerAddr.replace("{VALUE}", val).replace("{TYPE}", type)
    }
}
