export const isAmountFilterHasValue = (min, max) => {
  if (min !== null && min !== '' && min !== undefined && 
      max !== null && max !== '' && max !== undefined) {
    return 'minmax'
  }

  if (max !== null && max !== '' && max !== undefined && 
      (min === null || min === '' || min === undefined)) {
    return 'max'
  }

  if (min !== null && min !== '' && min !== undefined && 
      (max === null || max === '' || max === undefined)) {
    return 'min'
  }

  return null
}
