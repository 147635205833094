<template>
  <gl-modal
    v-bind="$attrs"
    cancel-title="No"
    full-buttons
    submit-title="Yes"
    :title="`ARE YOU SURE YOU WANT TO LEAVE?`"
    width="600"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="mb-4">
      Case has been changed. The current changes will be lost.
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    searchMode: {
      type: String,
      default: 'tx',
    },
  },
  data() {
    return {
      value: '',
    }
  },
  methods: {
    onSubmit() {
      this.$emit('add', this.value)
      this.$emit('input', false)
    },
  },
}
</script>
