<template>
  <div class="flex">
    <gl-menu-item
      v-popover:tooltip.top="'Copy'"
      icon="copy"
      :icon-height="24"
      :icon-width="24"
      @click="copy(tx ? tx : address)"
    />
    <gl-icon
      v-if="addDataToGraph"
      :height="24"
      name="add"
      :width="24"
      @click="$emit('add-tx-data', tx ? tx : address)"
    />
  </div>
</template>

<script>
// Vuex
import { mapState } from 'vuex'
// Components
import GlIcon from '@/components/gl-icon'
import GlMenuItem from '@/components/gl-menu-item'

export default {
  name: 'AddressTxActions',
  components: {
    GlIcon,
    GlMenuItem,
  },
  props: {
    address: {
      type: String,
      default: '',
    },
    tx: {
      type: String,
      default: '',
    },
    addDataToGraph: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('analytics', ['coinData']),
  },
  methods: {
    async copy(value) {
      await navigator.clipboard.writeText(value).then(() => {
        this.$toasted.global.success({ message: 'Copied!' })
      })
    },
  },
}
</script>
