<template>
  <div
    class="toolbar-actions-wrapper"
  >
    <div class="flex align-center space-between mb-3">
      <div class="bold">
        {{ title }}
      </div>
      <gl-icon
        :height="24"
        name="clear-large-dark"
        :width="24"
        @click="$emit('close')"
      />
    </div>
    <slot />
  </div>
</template>

<script>
// Components
import GlIcon from "@/components/gl-icon";

export default {
  components: {
    GlIcon
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>
.toolbar-actions-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 48px;
  padding: 16px;
  height: fit-content;
  max-height: 70%;
  overflow: auto;
  background: var(--white);
}
.menu-dropdown-items-wrapper label + label {
    margin-top: 10px;
}

.menu-dropdown-items-wrapper hr {
    margin: 16px 0;
}

.menu-dropdown-items-wrapper__padder {
    padding: 16px;
}
@media (max-width: 767px) {
  .toolbar-actions-wrapper {
    z-index: 12;
  }
}
</style>
