<template>
  <gl-modal
    v-bind="$attrs"
    submit-title="Check"
    title="Check sources"
    v-on="$listeners"
    @submit="onSubmit"
  >
    <gl-input
      v-model="outTxHash"
      class="gl-modal__row"
      label="Out Tx Hash"
      name="out tx hash"
      rules="required|txHash"
    />
    <gl-input
      v-model="sourceTxHash"
      class="gl-modal__row"
      label="Source Tx Hash"
      name="source tx hash"
      rules="required|txHash"
    />
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'
import { mapActions } from 'vuex'

export default {
  components: {
    GlModal,
    GlInput,
  },
  inheritAttrs: false,
  data() {
    return {
      outTxHash: '',
      sourceTxHash: '',
    }
  },
  methods: {
    ...mapActions('analytics', ['checkSources']),
    async onSubmit() {
      //TODO temp solution rewrite this
      const { data } = await this.checkSources({ outTxHash: this.outTxHash, sourceTxHash: this.sourceTxHash })
      this.$emit('input', false)
      alert(JSON.stringify(data))
    },
  },
}
</script>
