<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    submit-title="Add"
    :title="`${label}`"
    v-on="$listeners"
    @close="value = ''; $emit('close')"
    @submit="onSubmit"
  >
    <gl-input
      v-model="value"
      class="gl-modal__row"
      :label="label"
      name="value"
      :rules="rules"
    />
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'

export default {
  components: {
    GlModal,
    GlInput,
  },
  inheritAttrs: false,
  data() {
    return {
      value: '',
    }
  },
  computed: {
    label() {
      return 'ADD TX HASH / WALLET ADDRESS'
    },
    rules() {
      return 'required|address$OR$txHash'
    },
  },
  methods: {
    onSubmit() {
      this.$emit('add', this.value)
      this.value = ''
      this.$emit('input', false)
    },
  },
}
</script>
