<template>
  <div
    class="score-dot"
    :style="`background-color: ${findColorByTypeScore(
      score,
    )}; min-height: ${width}; min-width: ${width}; max-height: ${width}; max-width: ${width}`"
  />
</template>

<script>
// Utils
import { findColorByTypeScore } from '@/utils/cytoskape-ui-rules'

export default {
  components: {},
  props: {
    score: {
      type: Number,
      default: null,
    },
    width: {
      type: String,
      default: '8px',
    },
  },
  methods: {
    findColorByTypeScore,
  },
}
</script>

<style>
.score-dot {
  border-radius: 50%;
}
</style>
