<template>
  <gl-modal
    v-bind="$attrs"
    title="Customize Wallet"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <gl-input
      v-model="description"
      class="gl-modal__row"
      label="Description"
      name="description"
    />
    <div class="gl-modal__row">
      <label class="gl-input__label">
        Color
      </label>
      <v-swatches
        v-model="color"
        inline
        shapes="circles"
        show-checkbox
        :swatch-style="{marginRight: '14px'}"
        :wrapper-style="{marginTop: '10px'}"
      />
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

export default {
  components: {
    GlModal,
    GlInput,
    VSwatches,
  },
  inheritAttrs: false,
  props: {
    selectedNode: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      description: '',
      color: '',
    }
  },
  watch: {
    selectedNode: {
      immediate: true,
      handler(value) {
        if (value) {
          this.description = value.description
          this.color = value.color
        }
      },
    },
  },
  methods: {
    onSubmit() {
      this.$emit('customize-node', { description: this.description, color: this.color })
      this.$emit('input', false)
    },
    test() {
      this.color = null
      this.onSubmit();
    },
  },
}
</script>
