import { render, staticRenderFns } from "./newTokenSelect.vue?vue&type=template&id=054e3fef&scoped=true"
import script from "./newTokenSelect.vue?vue&type=script&lang=js"
export * from "./newTokenSelect.vue?vue&type=script&lang=js"
import style0 from "./newTokenSelect.vue?vue&type=style&index=0&id=054e3fef&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "054e3fef",
  null
  
)

export default component.exports