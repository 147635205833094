<template>
  <svg
    fill="none"
    height="12"
    viewBox="0 0 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.81555 3.75C10.4241 3.75 10.7289 4.49547 10.2986 4.93146L6.48301 8.79729C6.21625 9.06757 5.78375 9.06757 5.51699 8.79729L1.70144 4.93146C1.27112 4.49547 1.57589 3.75 2.18445 3.75H9.81555Z"
      :fill="`var(${color})`"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '--space-cadet',
    }
  }
}
</script>