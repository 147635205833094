<template>
  <div class="fs-14">
    <div>
      <div class="sidebar__section padder">
        <p class="sidebar__analytics-label">
          {{ elementLabel }}
        </p>
        <p
          class="sidebar__analytics-value flex align-center pp-address"
          :title="selectedElement.value"
        >
          <span class="m-word-break-all">
            {{ selectedElement.value }}
          </span>
          <gl-menu-item
            v-popover:tooltip.top="'Copy address'"
            class="sidebar__history-copy"
            icon="copy"
            :icon-height="24"
            :icon-width="24"
            @click="copy(selectedElement.value)"
          />
          <gl-menu-item
            v-if="isAdmin"
            v-popover:tooltip.top="'Edit labels'"
            class="sidebar__to-labeling"
            icon="edit-block-active"
            :icon-height="24"
            :icon-width="24"
            @click="toLabeling(selectedElement.value)"
          />
        </p>
      </div>
      <div class="stat">
        <div class="stat-tag full">
          <div class="stat-item full">
            <div class="key">
              Entity
            </div>
            <div class="value">
              <VueSkeletonLoader
                v-if="isAddressDataLoading"
                animation="wave"
                :color="'#bec3d8'"
                :height="20"
                :radius="'2'"
                type="rect"
              />
              <div
                v-else
                class="flex"
              >
                <span
                  :class="{'link-text': addressData && addressData.clusterData && addressData.clusterData.entityId}"
                  @click="goToEntity(addressData && addressData.clusterData && addressData.clusterData.entityId)"
                >
                  {{ addressData.clusterData
                    && addressData.clusterData.owner }}
                </span>
                <span
                  v-if="addressData
                    && addressData.owner
                    && addressData.clusterData
                    && addressData.clusterData.owner
                    && addressData.owner !== addressData.clusterData.owner"
                  class="mr-1"
                >,</span>
                <span
                  v-if="addressData.owner !== (addressData.clusterData && addressData.clusterData.owner)"
                  :class="{'link-text': addressData.entityId}"
                  @click="goToEntity(addressData.entityId)"
                >
                  {{ addressData.owner }}
                </span>
                <span
                  v-if="!addressData.owner && !(addressData.clusterData
                    && addressData.clusterData.owner)"
                >
                  Not identified
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="sidebar__section">
          <p class="sidebar__analytics-label">
            Tags
          </p>
          <VueSkeletonLoader
            v-if="isAddressDataLoading"
            animation="wave"
            :color="'#bec3d8'"
            :height="20"
            :radius="'2'"
            type="rect"
          />
          <div
            v-else-if="addressData && addressData.scoringList && addressData.scoringList.length > 0"
          >
            <GlTag
              v-for="(tag, index) in addressData.scoringList"
              :key="index"
              class="mr-1 mb-1"
              :class="{'hidden': index > 6}"
              :score="tag.score"
              :tag="tag.name"
            />
            <div
              v-if="addressData.scoringList && addressData.scoringList.length > 6"
              class="all-tags-btn"
              @click="changeTab('tags')"
            >
              All tags
            </div>
          </div>
          <div
            v-if="addressData && addressData.tags && addressData.tags.length === 0
              && !addressData.type && (!addressData.clusterData || !addressData.clusterData.type)"
            class="pp-tags-empty-block"
          >
            --
          </div>
          <p
            v-if="featureAccess('ALERTS')"
            class="sidebar__analytics-label mt-3 pp-profile-flag"
          >
            Profile Flag
          </p>
          <div
            v-if="featureAccess('ALERTS')"
          >
            <GlFlagsWrap
              :flags-data="flagsData"
              hide-score
              :loading="isFlagsDataLoading"
              :max-count="5"
            />
          </div>
          <div v-else-if="featureAccess('ALERTS')">
            --
          </div>
        </div>
      </div>
    </div>
    <div
      class="analytics-tabs"
      :class="{ 'tab-loading': isAddressDataLoading }"
    >
      <div
        v-for="(tab, index) in tabs"
        :key="index"
      >
        <div
          v-if="tab.visible"
          class="analytics-tabs-item"
          :class="[{'analytics-tabs-item--active': activeTab === tab.name},
                   {'hatching': addressData.clusterData && !addressData.clusterData.cluster && tab.name === 'cluster'},
                   {'hatching': !addressData.type
                     && addressData.tags
                     && addressData.tags.length === 0
                     && (!addressData.clusterData || !addressData.clusterData.type)
                     && (!addressData.clusterData || !addressData.clusterData.tags)
                     && tab.name === 'tags'}]"
          @click="changeTab(tab.name)"
        >
          {{ tab.title }}
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="activeTab === 'source'"
        class="relative"
      >
        <div v-if="calcLoading">
          <gl-loader class="table__loader" />
        </div>
        <template v-else-if="appConfig.VUE_APP_SCORE_CHART_DONUT">
          <div class="px-3 flex align-baseline space-between pp-exposure-header">
            <h2 class="sidebar__title mb-2">
              Source of Funds
            </h2>
            <div class="flex fs-14 align-end mr-3">
              Amount
              <div class="px-1 switch__wrap">
                <input
                  id="switch"
                  v-model="test"
                  type="checkbox"
                  @click="trackBy()"
                >
                <label for="switch">Toggle</label>
              </div>
              %
            </div>
          </div>

          <div
            v-if="!uofData.incomingData.typeGrouping.length"
            class="empty-users-data text-center"
          >
            Data is empty
          </div>

          <div
            v-else
            class="flex align-center justify-center relative"
          >
            <gl-pie
              v-if="!isMobile && !calcLoading"
              ref="pie"
              :center="['50%', '60%']"
              class="relative aml-detected-list"
              :data-source="uofData.incomingData.typeGrouping"
              :height="470"
              :width="'100%'"
              @mouseout="sectionData = null"
              @move="handlePieHover"
            >
              <template #tooltip>
                <Transition name="fade">
                  <div
                    v-if="sectionData && sectionData.owners"
                    id="sourcePie"
                    class="testPie"
                  >
                    <div
                      v-for="(owner, index) in sectionData.owners"
                      :key="index"
                      :class="{'mb-2': index < sectionData.owners.length - 1}"
                    >
                      <div class="flex align-center bold">
                        <div class="sub-types-amount">
                          <span>
                            {{ trackByField === 'share'
                              ? formatShare(pieData.length === 1 && sectionData.owners.length === 1 ? 1 : owner[trackByField])
                              : owner.formattedAmount || formatBtcAmount(owner[trackByField]) }}
                          </span>
                        </div>
                        <div
                          v-popover:tooltip.top="`${capitalizeFirstLetter(owner.name)}`"
                          class="ml-3 ellipsis sub-types-owner max-w-300"
                        >
                          {{ capitalizeFirstLetter(owner.name) }}
                        </div>
                      </div>
                      <div
                        v-if="trackByField === 'amount'"
                        class="price fs-13"
                      >
                        {{ formatByPrice(owner['amountCur']) }}
                      </div>
                    </div>
                  </div>
                </Transition>
              </template>
            </gl-pie>
            <PieDataList
              v-if="isMobile"
              class="m-fullwidth px-3"
              :data="uofData.incomingData.typeGrouping"
              :legend="null"
              title=""
              track-by-label="funds.type"
              track-by-label-support="funds.name"
              :track-value-by-field="trackByField"
            />
            <div class="risk-score-wrap">
              <strong class="risk-score-label">
                Risk Score
              </strong>
              <div
                v-if="totalFunds !== null"
                class="risk-score-value"
                :style="`color: ${findColorByTypeScore(totalFunds)}`"
              >
                {{ formatFunds(totalFunds, false) }}
              </div>
            </div>
          </div>
        </template>
        <div
          v-else-if="(uofData.incomingData.typeGrouping.length || uofData.outgoingData.typeGrouping.length)
            && !appConfig.VUE_APP_SCORE_CHART_DONUT"
        >
          <div class="px-3 flex flex-wrap space-between mt-3">
            <GlSelectButton
              :disabled="calcLoading"
              :options="partBarOptions"
              :value="activePartBar"
              @input="changeActivePartBarValue"
            />
            <GlSelectButton
              v-if="Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2"
              :disabled="calcLoading"
              :options="exposurePartOptions"
              :value="activeExposurePart"
              @input="changeActiveExposure"
            />
          </div>
          <div>
            <div class="px-3 pt-2">
              <div class="chart-wrap__side-panel">
                <gl-scoring-bar-chart
                  :active-part-bar="activePartBar"
                  :basis-percent-graph-width="30"
                  class="transparent-space-right"
                  :data-table="activeBarData"
                  full
                  :loading="calcLoading"
                  :max-share="uofData[`${activeExposurePart.value}Data`].maxShare"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="empty-users-data text-center"
        >
          Data is empty
        </div>
        <div class="source__action-block mt-2">
          <div
            v-if="(addressIsOwnerByHightRisk
              || hasDirectlyMixing
              || Number(addressRiskPercent) > 0
              || addressAreUnidentified
              || hasTagMoreRiskPoint) && !calcLoading"
            class="mb-2"
          >
            <div class="flex align-center">
              <p class="sidebar__analytics-label__simple mr-2">
                AML RISK DETECTED
              </p>
              <gl-menu-item
                icon="risk-mark"
                :icon-height="16"
                :icon-width="16"
                :label="showRiskMsg ? 'Hide' : 'Show'"
                @click="showRiskMsg = !showRiskMsg"
              />
            </div>
            <div v-if="showRiskMsg">
              <StatusBlock
                v-if="addressIsOwnerByHightRisk"
                class="mb-1"
                label="Address is owned by a high risk entity"
              />
              <StatusBlock
                v-if="hasDirectlyMixing"
                class="mb-1"
                label="Address has directly participated in mixing"
              />
              <StatusBlock
                v-if="Number(addressRiskPercent) > 0"
                class="mb-1"
                :label="addressRiskPercentLabel"
              />
              <StatusBlock
                v-if="addressAreUnidentified"
                class="mb-1"
                label="More than 75% of sources for the address are unidentified"
              />
              <StatusBlock
                v-if="hasTagMoreRiskPoint"
                class="mb-3"
                label="Address has directly participated in high risk activities"
              />
            </div>
          </div>
          <div class="gl-form__actions">
            <button
              v-if="!addressData.isMonitoring"
              class="gl-button gl-button--light full-submit gl-form__button gl-button--padder mr-4"
              :disabled="calcLoading"
              @click="addToMonitoringList(addressData.address)"
            >
              Add to Monitoring
            </button>
            <button
              class="gl-button gl-button--dark full-submit gl-form__button gl-button--padder"
              @click="toFullReport(addressData.address)"
            >
              get full report
            </button>
          </div>
        </div>
      </div>
      <div v-else-if="activeTab === 'transactions'">
        <div class="stat mt-3">
          <div class="stat-tag full new-transactions-block">
            <InfoBlock
              label="Balance"
              :loading="isTxDataLoading"
              :rate="txData
                && txData.balancePrice
                && formatByPrice(txData.balancePrice)"
              rate-time="current"
              :rate-timestamp="txData && txData.balancePriceTimestamp"
              :value="txData
                && txData.formattedBalance || (txData.balance
                  && toComaSeparate(String(formatBtcAmount(txData.balance))) || '0')"
            />
            <InfoBlock
              label="Number of txs"
              :loading="isTxDataLoading"
              :value="txData
                && txData.totalTx
                && toComaSeparate(String(txData.totalTx))"
            />
            <InfoBlock
              label="Total sent"
              :loading="isTxDataLoading"
              :rate="txData
                && txData.amountSentPrice
                && formatByPrice(txData.amountSentPrice)"
              rate-time="current"
              :rate-timestamp="txData && txData.amountSentPriceTimestamp"
              :value="txData
                && txData.formattedAmountSent || (txData.amountSent
                  && toComaSeparate(String(formatBtcAmount(txData.amountSent))))"
            />
            <InfoBlock
              label="total received"
              :loading="isTxDataLoading"
              :rate="txData
                && txData.amountReceivedPrice
                && formatByPrice(txData.amountReceivedPrice)"
              rate-time="current"
              :rate-timestamp="txData && txData.amountReceivedPriceTimestamp"
              :value="txData
                && txData.formattedAmountReceived || (txData.amountReceived
                  && toComaSeparate(String(formatBtcAmount(txData.amountReceived))))"
            />
            <InfoBlock
              label="first seen"
              :loading="isTxStatsDataLoading"
              :value="firstSeen ? `${formatDate(firstSeen, 'dd.MM.yyyy HH:mm')}` : null"
            />
            <InfoBlock
              label="last seen"
              :loading="isTxStatsDataLoading"
              :value="lastSeen ? `${formatDate(lastSeen, 'dd.MM.yyyy HH:mm')}` : null"
            />
            <InfoBlock
              label="Total tx sent"
              :loading="isTxDataLoading"
              :value="txData
                && txData.totalTxSent
                && toComaSeparate(String(txData.totalTxSent))"
            />
            <InfoBlock
              label="Total tx received"
              :loading="isTxDataLoading"
              :value="txData
                && txData.totalTxReceived
                && toComaSeparate(String(txData.totalTxReceived))"
            />
          </div>
          <div class="new-direction-filter">
            <GlSelectButton
              grey
              :options="directionsOptions"
              :value="activeDirection"
              @input="changeDirection($event)"
            />
          </div>
          <div class="column full pp-transactions-list">
            <div class="new-tx-filter">
              <div class="new-tx-filter__left">
                <date-picker
                  ref="datePicker"
                  v-model="transactionsListDates"
                  class="new-datepicker"
                  :disabled-date="disabledRange"
                  format="DD.MM.YYYY HH:mm:ss"
                  placeholder="All time"
                  range
                  range-separator=" - "
                  :show-time-panel="showTimeRangePanel"
                  type="datetime"
                  @clear="handleDateClose"
                  @close="handleRangeClose"
                >
                  <template v-slot:input="{ props }">
                    <div 
                      class="new-datepicker__input"
                      :class="{ 'new-datepicker__input--value' : props.value }"
                    >
                      <div class="new-datepicker__input-calendar-icon">
                        <CalendarIcon :color=" props.value ? '--datepicker-blue' : '--space-cadet'" />
                      </div>
                      <div class="new-datepicker__input-content">
                        {{ props.value ? props.value : props.placeholder }}
                      </div>
                      <div 
                        v-if="props.value" 
                        class="new-datepicker__input-close-icon" 
                        @click.stop="handleDateClose"
                      >
                        <ClearIcon />
                      </div>
                      <div 
                        v-else 
                        class="new-datepicker__input-arrow-icon"
                      >
                        <ArrowIcon />
                      </div>
                    </div>
                  </template>
                  <template v-slot:footer>
                    <div class="flex space-between">
                      <div>
                        <button
                          class="mx-btn mx-btn-text link"
                          @click="toggleTimeRangePanel"
                        >
                          {{ showTimeRangePanel ? 'Select date' : 'Select time' }}
                        </button>
                      </div>
                      <div>
                        <button
                          class="mx-btn mx-btn-text cancel-date-range mr-2"
                          @click="handleDateClose"
                        >
                          Cancel
                        </button>
                        <button
                          class="mx-btn mx-btn-text apply-date-range"
                          @click="setDate(transactionsListDates)"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </template>
                </date-picker>
              </div>
              <div class="new-tx-filter__right">
                <div 
                  class="new-amount__input"
                  :class="{ 'new-amount__input--value' : isAmountFilterHasValue(filters.amount.min, filters.amount.max) }"
                  @click="toggleFilter"
                >
                  <div 
                    v-if="isAmountFilterHasValue(filters.amount.min, filters.amount.max)"
                    class="new-amount__input-amount"
                  >
                    <div 
                      v-if="isAmountFilterHasValue(filters.amount.min, filters.amount.max) == 'min' || isAmountFilterHasValue(filters.amount.min, filters.amount.max) == 'minmax'" 
                      class="new-amount__input-amount-min"
                    >
                      {{ `${isAmountFilterHasValue(filters.amount.min, filters.amount.max) == 'min' ? 'From' : ''} ${filters.amount.min} ${coinType.toUpperCase()}` }}
                    </div>
                    <div 
                      v-if="isAmountFilterHasValue(filters.amount.min, filters.amount.max) == 'minmax'" 
                      class="new-amount__input-amount-divider"
                    >
                      -
                    </div>
                    <div 
                      v-if="isAmountFilterHasValue(filters.amount.min, filters.amount.max) == 'max' || isAmountFilterHasValue(filters.amount.min, filters.amount.max) == 'minmax'" 
                      class="new-amount__input-amount-max"
                    >
                      {{ `${isAmountFilterHasValue(filters.amount.min, filters.amount.max) == 'max' ? 'To' : ''} ${filters.amount.max} ${coinType.toUpperCase()}` }}
                    </div>
                  </div>
                  <div v-else>Amount</div>
                  <div 
                    v-if="isAmountFilterHasValue(filters.amount.min, filters.amount.max)" 
                    class="new-datepicker__input-close-icon" 
                    @click.stop="clearAmountFilter"
                  >
                    <ClearIcon />
                  </div>
                  <div 
                    v-else 
                    class="new-datepicker__input-arrow-icon"
                  >
                    <ArrowIcon />
                  </div>
                </div>
                <ValidationObserver
                  ref="txsListFilter"
                  slim
                >
                  <div
                    v-show="showFilter"
                    class="txs-list-filter-wrapper"
                  >
                    <div class="flex space-between pa-3">
                      <div class="bold-600">
                        Filters
                      </div>
                      <div
                        class="flex align-center pointer soft-blue-text bold"
                        @click="clearFilters"
                      >
                        Clear filters
                      </div>
                    </div>
                    <div class="grey-f-5-hr" />

                    <div class="pa-3">
                      <div class="bold fs-14 mb-3">
                        Amount
                      </div>
                      <div class="flex gap-4">
                        <gl-input
                          v-model="filters.amount.min"
                          class="flex-1"
                          :height="40"
                          label="Min"
                          :max="filters.amount.max"
                          name="min"
                          placeholder="Min"
                          type="number"
                        />
                        <gl-input
                          v-model="filters.amount.max"
                          class="flex-1"
                          :height="40"
                          label="Max"
                          :min="filters.amount.min"
                          name="max"
                          placeholder="Max"
                          type="number"
                        />
                      </div>
                    </div>

                    <div class="grey-f-5-hr" />
                    <div class="flex flex-end pa-3">
                      <div
                        class="pointer soft-blue-text bold"
                        @click="applyFilterAmount"
                      >
                        Apply
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </div>
            </div>
          </div>
          <o-table
            checkable
            checkbox-position="left"
            :checked-rows.sync="checkedRows"
            class="pagination-table table__overflow-auto address-info-table"
            :data="localData"
            hoverable
            :loading="transactionsListLoading"
            :mobile-cards="false"
            @check="addTransactionData"
            @check-all="addAllTransactionData"
          >
            <template
              v-if="transactionsListLoading"
              slot="loading"
              slot-scope="props"
            >
              <gl-loader class="table__loader" />
            </template>
            <template
              slot="empty"
              slot-scope="props"
            >
              <div
                v-if="!transactionsListLoading"
                class="flex justify-center full grey-text mt-3 mb-4"
              >
                Data is empty
              </div>
            </template>
            <o-table-column
              v-slot="props"
              field="date"
              label="Date & time"
              width="100"
            >
              <div class="min-w-80 m-white-space-nowrap">
                {{ formatDate(props.row.timestamp * 1000, 'dd.MM.yyyy HH:mm:ss') || '-' }}
              </div>
            </o-table-column>
            <o-table-column
              v-slot="props"
              field="transaction"
              label="transaction"
            >
              <div
                class="link pa-1"
                :class="{'text-highlight': props.row.tx_hash === hoveredHash}"
                @click="openInNewTab(props.row.tx_hash)"
                @mouseleave="hoveredHash=''"
                @mouseover="hoveredHash = props.row.tx_hash"
              >
                {{ trancateString(props.row.tx_hash, 15) }}
              </div>
            </o-table-column>
            <o-table-column
              v-slot="props"
              field="actions"
              label=""
            >
              <div class="link">
                <AddressTxActions
                  :tx="props.row.tx_hash"
                />
              </div>
            </o-table-column>
            <o-table-column
              v-slot="props"
              class="fullwidth"
              field="amount"
              label="amount"
            >
              <div
                class="flex column white-space-nowrap align-end fullwidth"
                :class="[
                  {'squander': Math.sign(props.row.amount) === -1},
                  {'profit': Math.sign(props.row.amount) === 1},
                ]"
              >
                <span>
                  {{ props.row.formattedAmount }}
                </span>
                <span
                  v-if="props.row.price"
                  class="fw-400 ml-1"
                > ({{ formatByPrice(props.row.price) }})
                  <gl-icon
                    v-popover:tooltip.top="`${priceMessage('historical', props.row.priceTimestamp)}`"
                    class="price-info-icon"
                    :height="11"
                    name="info"
                    :success="Math.sign(props.row.amount) === 1"
                    :warn="Math.sign(props.row.amount) === -1"
                    :width="11"
                  />
                </span>
              </div>
            </o-table-column>
          </o-table>
          <div
            v-if="localData.length > 0"
            class="flex flex-end fullwidth pa-1"
          >
            <o-pagination
              v-if="localData.length"
              class="transactions-list-pagination"
              :current.sync="currentPage"
              order="centered"
              :per-page="perPage"
              size="small"
              :total="total"
              @change="pageChange"
            >
              <o-pagination-button
                slot="previous"
                slot-scope="props"
                :page="props.page"
              >
                <gl-menu-item
                  class="change-page mr-2"
                  :disabled="props.page.disabled"
                  icon="left"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>

              <o-pagination-button
                slot="next"
                slot-scope="props"
                :page="props.page"
              >
                <gl-menu-item
                  class="change-page"
                  :disabled="props.page.disabled"
                  icon="right"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>
            </o-pagination>
          </div>
        </div>
      </div>
      <div v-else-if="activeTab === 'cluster'">
        <div class="stat mt-3">
          <div class="stat-tag full">
            <InfoBlock
              label="Cluster id"
              :value="addressData.clusterData && addressData.clusterData.cluster"
            />
            <InfoBlock
              label="Cluster size"
              :loading="isAddressDataLoading"
              :value="addressData
                && addressData.clusterData
                && addressData.clusterData.clusterSize
                && toComaSeparate(String(addressData.clusterData.clusterSize))"
            />
            <InfoBlock
              label="Entity"
              :link-text-value="Boolean(addressData && addressData.clusterData && addressData.clusterData.entityId)"
              :loading="isAddressDataLoading"
              :value="addressData
                && addressData.clusterData
                && addressData.clusterData.owner
                && String(addressData.clusterData.owner)"
              @clickedValue="goToEntity(addressData && addressData.clusterData && addressData.clusterData.entityId)"
            />
          </div>
        </div>
        <ClusterAddressesTable
          v-if="(addressData.clusterData && addressData.clusterData.cluster) || addressData.cluster"
          :cluster="addressData.clusterData.cluster || addressData.cluster"
          @add-tx-data="$emit('add-tx-data', $event)"
        />
      </div>
      <div v-else-if="activeTab === 'tags'">
        <div class="flex px-3 mt-2 mb-2 relative">
          <div
            class="gl-rounded-btn mr-2"
            :class="{'gl-button--dark': !switchTags}"
            @click="switchTags = false"
          >
            Address tags
          </div>
          <div
            class="gl-rounded-btn"
            :class="{'gl-button--dark': switchTags}"
            @click="switchTags = true"
          >
            Cluster tags
          </div>
        </div>
        <AddressTagsTable
          v-if="!switchTags"
          :address="selectedElement.value"
          :data="addressData"
        />
        <ClusterTagsTable
          v-else
          :cluster="selectedElement.data.cluster && String(selectedElement.data.cluster)
            || selectedElement.data.addressData && selectedElement.data.addressData.cluster"
          :data="addressData.clusterData"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Utils
import { formatDate, toSecondsStart, toSecondsEnd } from "@/utils/format-date";
import { toComaSeparate } from '@/utils/formatNumber';
import { formatBtcAmount } from '@/utils/format-btc-amount';
import { formatFunds, formatterAmountValue, calcSourcesRiskPercent, getEntityIds, separateSources } from '@/utils/report-data-formatter'
import { capitalizeFirstLetter, trancateString, spamMessagesCombine } from "@/utils/text-formatter";
import { formatShare, formatter } from "@/utils/sourcesFormatter";
import { sortingObjects, sourcesSortOrderArray } from "@/utils/sorting";
import { findColorByTypeScore } from "@/utils/cytoskape-ui-rules";
import { featureAccess } from "@/utils/accesses";
import { formatByPrice, priceMessage } from "@/utils/format-by-price";
import { goToEntity } from "@/utils/go-to-route";
import { isAmountFilterHasValue } from "@/utils/newFilters"
// Libs
import _ from 'lodash';
import moment from "moment";
// Vuex
import { mapActions, mapGetters, mapState } from 'vuex'
// Components
import GlPie from '@/components/charts/gl-pie-chart';
import { ValidationObserver } from 'vee-validate';
import GlInput from '@/components/gl-input.vue'
import GlTag from '@/components/gl-tag';
import DatePicker from 'vue2-datepicker';
import GlLoader from '@/components/gl-loader';
import InfoBlock from '@/components/gl-info-block';
import GlMenuItem from '@/components/gl-menu-item';
import GlRadio from '@/components/gl-radio-button';
import VueSkeletonLoader from 'skeleton-loader-vue';
import AddressTagsTable from './components/addressTagsTable';
import ClusterTagsTable from './components/clusterTagsTable';
import StatusBlock from '@/pages/report/components/StatusBlock';
import ClusterAddressesTable from './components/clusterAddressesTable';
import GlFlagsWrap from '@/components/gl-flags-wrap';
import AddressTxActions from './components/addressTxActions';
import GlIcon from '@/components/gl-icon'
import CalendarIcon from '@/pages/analytics/analytics-info-blocks/svg/calendar-icon'
import ArrowIcon from '@/pages/analytics/analytics-info-blocks/svg/arrow-icon'
import ClearIcon from '@/pages/analytics/analytics-info-blocks/svg/clear-icon'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import { AlertsMixin } from '@/assets/mixins/alertsMixin'
// Models
import uofModel from '@/pages/analytics/analytics-info-blocks/models/uofModel'
import appConfig from '@/utils/appConfig'
import GlSelectButton from '@/components/gl-select-button.vue'
import GlScoringBarChart from '@/components/charts/gl-scoring-bar-chart.vue'
import PieDataList from '@/pages/report/components/PieDataList.vue'

export default {
  name: 'AddressInfo',
  components: {
    PieDataList,
    GlPie,
    GlScoringBarChart,
    GlSelectButton,
    ValidationObserver,
    GlInput,
    GlMenuItem,
    InfoBlock,
    DatePicker,
    GlRadio,
    GlLoader,
    ClusterAddressesTable,
    StatusBlock,
    GlTag,
    VueSkeletonLoader,
    AddressTagsTable,
    ClusterTagsTable,
    GlFlagsWrap,
    AddressTxActions,
    GlIcon,
    CalendarIcon,
    ArrowIcon,
    ClearIcon,
  },
  mixins: [deviceWidthMixin, AlertsMixin],
  props: {
    selectedElement: {
      type: Object,
      default: () => ({})
    },
    cytoscape: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      showRiskMsg: false,
      flagsData: [],
      isFlagsDataLoading: false,
      monitoringCalculateHandler: null,
      addressMonitoringPending: false,
      addressData: {},
      txData: {},
      sectionData: [],
      trackByField: 'share',
      viewDonat: true,
      test: true,
      switchTags: false,
      allDataSource: [],
      activeTab: 'transactions',
      tabs: [
        {
          title: 'Transactions',
          name: 'transactions',
          visible: true
        },
        {
          title: 'Risk Exposure',
          name: 'source',
          visible: featureAccess('REPORT')
        },
        {
          title: 'Cluster',
          name: 'cluster',
          visible: true
        },
        {
          title: 'Tags & Metadata',
          name: 'tags',
          visible: true
        },
      ],
      pieData: [],
      firstSeen: null,
      lastSeen: null,
      startRangeDate: null,
      endRangeDate: null,
      currentDate: null,
      totalFunds: 0,
      sort: null,
      backward: false,
      forward: false,
      calcLoading: false,
      transactionsListLoading: false,
      isAddressDataLoading: false,
      isTxDataLoading: false,
      isTxStatsDataLoading: false,
      checkedRows: [],
      addressUnknownSources: [],
      preCheckedRows: [],
      localData: [],
      calcData: [],
      visualiseLimit: 100,
      pagesOptions: [5, 10,20, 50, 100],
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      totalTxs: 0,
      placement: 'top',
      transactionsListDates: [],
      showTimePanel: false,
      showTimeRangePanel: false,
      RISK_POINT: appConfig.VUE_APP_RISK_SCORE_TRESHOLD,
      addressRiskPercent: 0,
      hoveredHash: '',
      showFilter: false,
      filters: {
        amount: {
          min: null,
          max: null,
        },
      },
      uofData: uofModel(),
      activeExposurePart: { name: 'Source of Funds', value: 'incoming' },
      exposurePartOptions: [
        { name: 'Source of Funds', value: 'incoming' },
        { name: 'Use of Funds', value: 'outgoing' },
      ],
      activePartBar: { name: 'Type', value: 'funds.type', key: 'type' },
      partBarOptions: [
        { name: 'Type', value: 'funds.type', key: 'type' },
        { name: 'Entity', value: 'owner', key: 'owner' },
      ],
      step: 10,
      entityIdCache: {},
      directionsOptions: [
        { name: 'All', value: '2' },
        { name: 'Received', value: '0' },
        { name: 'Sent', value: '1' },
      ],
      activeDirection: {
        name: 'All',
        value: '2',
      },
    }
  },
  computed: {
    appConfig() {
      return appConfig
    },
    ...mapState('analytics', ['coinType', 'coinData']),
    ...mapGetters('user', ['isAdmin']),
    activeBarData() {
      return this.uofData[`${this.activeExposurePart.value}Data`][`${this.activePartBar.key}Grouping`];
    },
    showFilerSign() {
      return Boolean(this.filters.amount.min || this.filters.amount.max)
    },
    elementLabel() {
      return (this.selectedElement && this.selectedElement.type || '').replace('tx', 'Transaction Hash')
    },
    addressIsOwnerByHightRisk() {
      return (this.addressData?.tags?.find(tag => tag.score >= this.RISK_POINT))
        || (this.addressData?.clusterData?.tags.find(tag => tag.score >= this.RISK_POINT))
        || this.addressData?.type?.score >= this.RISK_POINT
        || (this.addressData?.clusterData?.type?.score >= this.RISK_POINT)
    },
    hasDirectlyMixing() {
      return (this.addressData?.type?.name === 'mixing')
        || (this.addressData?.clusterData?.type?.name === 'mixing')
        || (this.addressData?.tags.find(tag => tag.name === 'coin join participant'))
        || (this.addressData?.clusterData?.tags.find(tag => tag.name === 'coin join participant'))
    },
    hasTagMoreRiskPoint() {
      return (this.addressData?.tags.find(tag => tag.score >= this.RISK_POINT))
        || (this.addressData?.clusterData?.tags.find(tag => tag.score >= this.RISK_POINT))
    },
    addressAreUnidentified() {
      const sum = this.addressUnknownSources && this.addressUnknownSources.reduce((acc, { share }) => acc + share, 0)

      return sum * 100 >= 75
    },
    addressRiskPercentLabel() {
      if (this.activeExposurePart.value === 'outgoing') {
        return `Address sent ${formatShare(this.addressRiskPercent)} funds to risky destinations`
      }
      return `Address received ${formatShare(this.addressRiskPercent)} funds from risky sources`
    },
  },
  watch: {
    'selectedElement.value': 'updateData',
  },
  beforeDestroy() {
    this.$root.$off("open-node");
  },
  created() {
    this.$root.$on('open-node', this.setValue);
  },
  async mounted() {
    this.getAlertsData()
    await this.loadAddressData();
    if (!this.selectedElement.addressListData) {
      this.loadAddressInfo();
    } else {
      this.isOpenTransactionInfo = true
      await this.setAddressListData()
      await this.checkingRows()
    }
    await this.loadTxData();
    await this.loadTxStats();
    this.preSelectElement()
  },
  methods: {
    featureAccess,
    formatShare,
    formatFunds,
    toComaSeparate,
    findColorByTypeScore,
    capitalizeFirstLetter,
    priceMessage,
    trancateString,
    formatByPrice,
    goToEntity,
    toSecondsStart,
    toSecondsEnd,
    formatterAmountValue,
    spamMessagesCombine,
    calcSourcesRiskPercent,
    separateSources,
    isAmountFilterHasValue,
    async toggleFilter() {
      this.showFilter = !this.showFilter
    },
    async applyFilterAmount() {
      const isValid = await this.$refs.txsListFilter.validate()
      if (!isValid) return
      this.showFilter = false
      this.loadAddressInfo()
    },
    countChange() {
      localStorage.setItem('transactions-list-per-page', this.perPage)
      this.currentPage = 1
      this.loadAddressInfo()
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    disabledRange(date) {
      return date > moment().endOf('day');
    },
    pageChange(event) {
      this.currentPage = event
      this.loadAddressInfo()
    },
    changeTab(name = 'transactions') {
      if ((!this.addressData?.clusterData?.cluster && name === 'cluster') || (!this.addressData.type
            && this.addressData.tags && this.addressData.tags.length === 0
            && !this.addressData?.clusterData?.type
            && !this.addressData?.clusterData?.tags
            && name === 'tags')) return

      this.activeTab = name

      if (this.activeTab === 'source') {
        this.loadAddressData()
        this.loadCalculation()
      }
      this.$nextTick(() => {
        document.querySelector('.analytics-tabs').scrollLeft = document.querySelector('.analytics-tabs-item--active').offsetLeft - 10
      });
    },
    handlePieHover($event) {
      const a = document.getElementById('sourcePie')

      if (a) {
        a.style.left = `${$event.event.event.clientX + 10}px`
        a.style.top = `${$event.event.event.clientY + 10}px`
      }

      if ($event.event.event.clientX) {
        this.sectionData = $event.data || null
      }
    },
    trackBy() {
      if (this.trackByField === 'share') {
        this.trackByField = 'amount'
      } else {
        this.trackByField = 'share'
      }

      this.uofData.incomingData.typeGrouping = this.uofData.incomingData.typeGrouping.map(item => ({
        ...item,
        name: `${this.trackByField === 'share' ? formatShare(item.share) : this.formatBtcAmount(item[this.trackByField], true, 3)} ${item.funds.type}`,
        tooltip: `${item.funds.type} ${this.trackByField === 'share' ? formatShare(item.share) : this.formatBtcAmount(item[this.trackByField], true, 3)}`,
      }))
    },
    toLabeling(val) {
      const { href } = this.$router.resolve({ name: 'labeling', query: { address: val, tab: 'labeling', type: this.coinType }})

      window.open(href, '_blank')
    },
    async calkDataFormatter({ sources = [], currencies = {} }, direction, partOptions) {
      if (direction === this.activeExposurePart.value) {
        this.calcLoading = true;

        const uniqueOwners = [...new Set(sources.map(source => source.owner))];
        const entityIdMap = await getEntityIds({
          owners: uniqueOwners,
          entityIdCache: this.entityIdCache,
          getEntityList: this.getEntityList
        });

        sources = sources.map(source => ({
          ...source,
          entityId: entityIdMap[source.owner.toLowerCase()] || null,
        }));

        this.calcLoading = false;
      }

      this.uofData[`${direction}Data`].sources = sources
      this.uofData[`${direction}Data`].currencies = currencies
      this.uofData[`${direction}Data`][`${partOptions.key}Grouping`] = sortingObjects(formatter(sources, partOptions.value), sourcesSortOrderArray)
        .map((source) => {
          const localCurrency = currencies[source.currency]
            ? { ...currencies[source.currency], decimals: currencies[source.currency].decimals || 0 }
            : {}

          return {
            ...source,
            owners: sources
              .filter((v) => v.funds.type === source.funds.type)
              .map((v) => ({
                ...v,
                name: v.owner,
              })),
            funds: {
              ...source.funds,
              default: source.funds.default || false
            },
            key: source.entity,
            name: `${this.trackByField === 'share' ? `${formatShare(sources.length === 1 ? 1 : source.share)}` : this.formatBtcAmount(source[this.trackByField], true, 3)} ${source.funds.type}`,
            isOpen: false,
            value: source.share,
            tooltip: `${source.funds.type} ${this.trackByField === 'share' ? formatShare(source[this.trackByField]) : this.formatBtcAmount(source[this.trackByField], true, 3)}`,
            itemStyle: {color: source.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(source.funds.score)},
            currencyData: localCurrency,
            formattedAmount: this.toComaSeparate(String(this.formatterAmountValue(source.amount, localCurrency?.decimals ?? this.coinData.decimals, localCurrency?.currency || this.coinData.label))),
          }
        })

      this.uofData[`${direction}Data`][`${partOptions.key}Grouping`].map((item) => {
        item.owners = formatter(item.owners, 'name') || []

        item.owners.forEach(owner => {
          owner.formattedAmount = this.formatBtcAmount(owner.amount)
        });
      })

      const opIndex = this.exposurePartOptions.findIndex(({ value }) => value === direction)

      if (opIndex !== -1) {
        this.$set(this.exposurePartOptions[opIndex], 'disabled', Boolean(!sources.length))
      }

      const maxValue = this.uofData[`${direction}Data`][`${partOptions.key}Grouping`].reduce(
        (max, obj) => (obj.share > max ? obj.share : max),
        this.uofData[`${direction}Data`][`${partOptions.key}Grouping`][0]?.share,
      )

      this.uofData[`${direction}Data`].maxShare = Math.ceil((maxValue * 100) / this.step) * this.step

      if (direction === this.activeExposurePart.value) {
        this.addressUnknownSources = this.uofData[`${direction}Data`].sources.filter(source => source.listType === 'Unknown sources')

        this.addressRiskPercent = 0

        this.addressRiskPercent = this.calcSourcesRiskPercent(this.uofData[`${direction}Data`][`${partOptions.key}Grouping`])
      }
    },
    getAlertsData() {
      if (this.featureAccess('ALERTS')) {
        this.isFlagsDataLoading = true
        this.loadAlerts(this.selectedElement.value).then((data) => {
          this.flagsData = data || []
        }).catch(() => {
          this.flagsData = []
        }).finally(() => {
          this.isFlagsDataLoading = false
        })
      } else {
        this.flagsData = []
      }
    },
    async updateData() {
      this.filters = {
        amount: {
          min: null,
          max: null,
        },
      }
      this.currentPage = 1
      this.perPage = 10
      this.currentDate = null;
      this.sort = null;
      this.uofData = uofModel()
      this.changeTab();
      this.getAlertsData()
      await this.loadAddressData();
      await this.loadTxData();
      if (!this.selectedElement.addressListData) {
        await this.loadAddressInfo();
      } else {
        await this.setAddressListData()
        await this.checkingRows()
      }
      await this.loadTxStats();
      this.allDataSource = []
      this.sortedPieData()
      this.calcLoading = false
      this.preSelectElement()
    },
    setValue() {
      this.$nextTick(() => {
        this.loadAddressInfo()
        this.checkingRows()
      })
    },
    preSelectElement() {
      if (this.selectedElement.value) {
        this.cytoscape.searchElement(this.selectedElement.value, false, true)
      }
    },
    formatDate,
    formatBtcAmount,
    ...mapActions('analytics', ['getReportAddressAgg', 'getAddressData', 'getNewAddressInfo', 'getTransactionData', 'getTxStats', 'getReportAddress', 'calculationAddressRisk', 'getAddressBrowserList', 'getAddressBrowserDetails']),
    ...mapActions('monitoring', ['addToMonitoring', 'getAddressesMonitoring']),
    ...mapActions('entity', ['getEntityList']),
    checkingRows() {
      this.localData.map((item) => {
        if (this.cytoscape.searchElements(item.tx_hash, false, false).length > 0) {
          this.checkedRows.push(item)
        }
      })
      this.preCheckedRows = this.checkedRows;
    },
    setAddressListData() {
      this.localData = this.selectedElement.addressListData.dataList
      this.forward = this.selectedElement.addressListData.forward
      this.backward = this.selectedElement.addressListData.backward
    },
    changeActivePartBarValue(val) {
      this.activePartBar = val
      this.calkDataFormatter(
        this.uofData[`${this.activeExposurePart.value}Data`],
        this.activeExposurePart.value,
        this.activePartBar
      )
    },
    changeActiveExposure(val) {
      this.activeExposurePart = val
      this.calkDataFormatter(
        this.uofData[`${this.activeExposurePart.value}Data`],
        this.activeExposurePart.value,
        this.activePartBar
      )
    },
    loadCalculation() {
      this.calcLoading = true
      this.totalFunds = null

      this.getReportAddressAgg({ address: this.selectedElement.value }).then((data) => {
      this.totalFunds = data?.totalFunds ?? null;

      const { sofSources, uofSources } = this.separateSources(data?.sources)
      const currencies = data?.currencies

      this.calkDataFormatter({ sources: sofSources, currencies} || {}, 'incoming', this.activePartBar)
      this.calkDataFormatter({ sources: uofSources, currencies} || {}, 'outgoing', this.activePartBar)

      this.calcLoading = false;
      }).catch(({ response: { data } }) => {
        this.calkDataFormatter({}, 'incoming', this.activePartBar)
        this.calkDataFormatter({}, 'outgoing', this.activePartBar)

        if (data?.meta) {
          this.$toasted.global.info({ message: this.spamMessagesCombine({ meta: data?.meta }) })
        } else if (data.message) {
          this.$toasted.global.error({ message: data.message })
        }
        this.calcLoading = false
      }).finally(() => {
        const availableExposurePart = this.exposurePartOptions.find(part => !part.disabled)
        if (this.activeExposurePart.value !== availableExposurePart.value) {
          this.changeActiveExposure(availableExposurePart)
        }
        this.calcLoading = false
      })
    },
    gettingEntityByOwner(owner, isAddress = true) {
      if (owner) {
        this.getEntityList({ name: owner }).then((data) => {
          if (data?.items?.find(el => el?.name?.value.toLowerCase() === owner.toLowerCase())) {
            const entity = data?.items?.find(el => el?.name?.value === owner).id || null
            this.$nextTick(() => {
              isAddress ? this.$set(this.addressData, 'entityId', entity) :
               this.addressData && this.addressData.clusterData && this.$set(this.addressData.clusterData, 'entityId', entity)
            })
          }
        })
      }
    },
    async loadAddressData() {
      try {
        this.isAddressDataLoading = true;

        const { data, success } = await this.getAddressData({ address: this.selectedElement.value });

        if (success) {
          this.addressData = {
            ...data,
            tags: data.tags && data?.tags?.length > 0 ? data.tags : [],
            scoringList: this.formattingScoringList(data) || [],
          };

          this.gettingEntityByOwner(data?.owner, true)
          this.gettingEntityByOwner(data?.clusterData?.owner, false)
        } else {
          this.addressData = {};
        }
      } catch (error) {
        console.error('Error loading address data:', error);
      } finally {
        this.isAddressDataLoading = false;
      }
    },
    formattingScoringList(data) {
      let SCORING_LIST = []

      if (data.tags) {
        SCORING_LIST = [...SCORING_LIST, ...data.tags]
      }

      if (data?.clusterData?.tags) {
        SCORING_LIST = [...SCORING_LIST, ...data.clusterData.tags]
      }

      if (data.type) {
        SCORING_LIST = [...SCORING_LIST, ...(Array.isArray(data.type) ? data.type : [data.type])]
      }

      if (data?.clusterData?.type) {
        SCORING_LIST = [...SCORING_LIST, data.clusterData.type]
      }

      SCORING_LIST.sort((a, b) => ((a.score < b.score)) ? 1 : -1)

      return SCORING_LIST
    },
    loadTxStats() {
      this.isTxStatsDataLoading = true
      this.getTxStats(this.selectedElement.value).then(({data}) => {
        this.firstSeen = data.firstSeen ? data.firstSeen * 1000 : ''
        this.lastSeen = data.lastSeen ? data.lastSeen * 1000 : ''
      }).finally(() => {
        this.isTxStatsDataLoading = false
      })
    },
    loadAddressInfo() {
      this.transactionsListLoading = true
      this.getAddressBrowserList({
        address: this.selectedElement.value,
        startRangeDate: this.startRangeDate,
        endRangeDate: this.endRangeDate,
        currentDate: this.currentDate,
        page: this.currentPage,
        count: this.perPage,
        minAmount: this.filters.amount.min || 0,
        maxAmount: this.filters.amount.max || undefined,
        sort: this.sort,
        direction: this.activeDirection.value,
      }).then(({data: {list = [], backward, forward, totalTxs}}) => {
        this.total = totalTxs
        this.localData = list.map(el => {
          return {
            ...el,
            formattedAmount: toComaSeparate(formatBtcAmount(el.amount))
          }
        })
        this.backward = backward
        this.forward = forward
        this.localData.map((item) => {
          if (this.cytoscape.searchElements(item.tx_hash, false, false).length > 0) {
              if (this.cytoscape.cy.nodes(`#${this.selectedElement.value}`).connectedEdges().length
                  && this.cytoscape.cy.nodes(`#${this.selectedElement.value}`).connectedEdges().map(el => el.data()).find(tx => tx.txHash === item.tx_hash))
              {
                  this.checkedRows.push(item)
                  this.cytoscape.replaceTxHash(this.selectedElement.value, item.tx_hash , Math.sign(item.amount) !== -1 ? 'prev' : 'next')
              }
          }
        })
        this.preCheckedRows = this.checkedRows;
      }).finally(() => {
        this.transactionsListLoading = false
      })
    },
    toggleCalendarHandler() {
      this.$emit('toggle-calendar')
    },
    setDate(date) {
      const [from, to] = date

      if (from && to) {
        this.startRangeDate = this.toSecondsStart(from);
        this.endRangeDate = this.toSecondsEnd(to);
        this.transactionsListDates = [new Date(this.startRangeDate * 1000), new Date(this.endRangeDate * 1000)]
        this.currentPage = 1
        this.$refs.datePicker.closePopup()
        this.loadAddressInfo();
      } else this.clearFilters()
    },
    handleDateClose() {
      this.transactionsListDates = []
      this.startRangeDate = undefined;
      this.endRangeDate = undefined;
      this.$refs.datePicker.closePopup()
      this.loadAddressInfo();
    },
    clearAmountFilter() {
      this.filters = {
        amount: {
          min: null,
          max: null
        }
      }
      this.showFilter = false
      this.loadAddressInfo()
    },
    clearFilters() {
      this.filters = {
        amount: {
          min: null,
          max: null
        }
      }
      this.showFilter = false
      this.transactionsListDates = []
      this.startRangeDate = undefined;
      this.endRangeDate = undefined;
      this.loadAddressInfo();
    },
    handleDiffCheckedRows(checkedRows, currData) {
      const diffItem = _.difference(checkedRows, currData)
      if (diffItem.length > 0) {
        diffItem.forEach(ele => {
          this.cytoscape.searchAndHideElementsByTxHash(ele.tx_hash, ele)
        })
        this.$emit('search-and-hide-elements')
      }
    },
    addTransactionData(val = []) {
      const selectedEl = val[val.length - 1]

      if (val.length > this.checkedRows.length) {

        this.getAddressBrowserDetails({ hash: selectedEl.tx_hash, address: this.selectedElement.value }).then(({ data }) => {

          this.checkedRows = this.checkedRows.map(row => {
            return Object.assign(row, selectedEl.tx_hash === row.tx_hash ? data : {})
          })

          this.handleDiffCheckedRows(this.checkedRows, val)
          let bigData = []

          bigData.push(data.inputs, data.outputs)
          bigData = bigData.flat().length

          if (bigData <= this.visualiseLimit || (bigData > this.visualiseLimit && confirm(`Selected transaction has ${bigData} addresses. Continue?`))) {
            this.$emit('set-transaction', {
              data: [selectedEl],
              address: this.selectedElement.value
            })

            this.$nextTick(() => {
              this.cytoscape.updateData(selectedEl.tx_hash, {
                inputsPerPage: 5,
                outputsPerPage: 5,
                inputsSkip: 0,
                outputsSkip: 0,
              })
              this.checkedRows.forEach((el) => {
                this.cytoscape.replaceTxHash(this.selectedElement.value, el.tx_hash , Math.sign(el.amount) !== -1 ? 'prev' : 'next')
              })
            })
          } else {
            this.$nextTick(() => {
              if (this.checkedRows.length) {
                this.$nextTick(() => {
                  this.checkedRows = this.checkedRows.filter((row) => {
                    return row.tx_hash !== selectedEl.tx_hash
                  })
                })
              }
            })
          }
        })
      } else {
        this.handleDiffCheckedRows(this.checkedRows, val)
        const diffItem = _.difference(this.checkedRows, val)
        if (diffItem.length > 0) {
          diffItem.forEach(ele => {
            this.cytoscape.setTxHash(this.selectedElement.value, ele.tx_hash, Math.sign(selectedEl.amount) !== -1 ? 'next' : 'prev')
          })
        }
      }
    },
    addAllTransactionData(val) {
      this.handleDiffCheckedRows(this.checkedRows, val)
      if (val.length > this.checkedRows.length) {
        this.localData.forEach((item) => {
          this.getAddressBrowserDetails({ hash: item.tx_hash, address: this.selectedElement.value }).then(({ data }) => {
            const foundData = Object.assign(item, data.tx_hash === item.tx_hash ? data : {})

            this.$emit('set-transaction', {
              data: foundData ? [foundData] : [],
              address: this.selectedElement.value
            })
          })
        })
      }
    },
    openInNewTab(tx) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { tx, type: this.coinData.key } })
      window.open(href, '_blank')
    },
    loadTxData() {
      this.isTxDataLoading = true
      this.getTransactionData(this.selectedElement.value)
        .then(({ data }) => {
          this.txData = {
            ...data,
            formattedAmountSent: this.toComaSeparate(this.formatterAmountValue(data.amountSent, this.coinData.decimals, this.coinData.label)),
            formattedAmountReceived: this.toComaSeparate(this.formatterAmountValue(data.amountReceived, this.coinData.decimals, this.coinData.label)),
            formattedBalance: this.toComaSeparate(this.formatterAmountValue(data.balance, this.coinData.decimals, this.coinData.label))
          };
        })
        .finally(() => {
          this.isTxDataLoading = false
        })
    },
    async copy(value) {
      await navigator.clipboard.writeText(value).then(() => {
        this.$toasted.global.success({ message: 'Copied!' })
      })
    },
    addToMonitoringList(address) {
      this.calcLoading = true
      this.addToMonitoring({
        address,
        checkLastTen: false,
        blockchain: this.coinData.label
      }).then(({ success, data }) => {
        if (success) {
          this.addressMonitoringPending = true
          this.$toasted.global.success({message: `Address added to monitoring`})
          this.getAddressesMonitoring({ skip: 0, count: 1, address})
              .then(() => {
                this.monitoringCalculateHandler = setTimeout(() => {
                  this.monitoringGettingInterval(address)
                }, 3000);
              })
          this.addressData.isMonitoring = true
        }
        else {
          this.$toasted.global.error({message: `${data.message}`})
        }
      })
    },
    monitoringGettingInterval(address) {
      this.monitoringCalculateHandler = setTimeout(() => {
        this.getAddressesMonitoring({ skip: 0, count: 1, address})
            .then(({ data }) => {
              if (data.list.length && data.list[0].riskScore !== undefined) {
                this.calcLoading = false
                clearTimeout(this.monitoringCalculateHandler);
                this.loadCalculation()
              } else {
                this.monitoringGettingInterval(address)
              }
            })
      }, 3000)
    },
    toFullReport(address) {
      const { href } = this.$router.resolve({ name: 'report', query: { address, type: this.coinType } })
      window.open(href, '_blank')
    },
    changeDirection(direction) {
      this.currentPage = 1
      this.activeDirection = direction
      this.loadAddressInfo()
    },
  },
}
</script>

<style scoped>
.analytics-tabs {
  margin-top: 3px;
  position: relative;
  flex-flow: row;
}
.analytics-tabs > div:nth-child(4),
.analytics-tabs > div:nth-child(4) > .analytics-tabs-item {
  width: 150px;
}
.analytics-tabs > div:nth-child(4) > .analytics-tabs-item {
  padding-left: 10px;
}

.stat {
  display: flex;
  flex-wrap: wrap;
  padding: 0 24px 0 16px;
}
.change-date {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}
.stat-item {
  width: 25%;
  word-break: break-word;
  margin-bottom: 24px;
  padding-right: 8px;
  font-size: 14px;
}
.value {
  font-weight: 500;
}
.full {
  width: 100%;
}
.value {
  font-weight: 500;
}

.sidebar__to-labeling {
  padding-bottom: 4px;
}

.key {
  font-size: 12px;
  font-weight: 500;
  color: var(--dark-grey-6-e);
  text-transform: uppercase;
  margin-bottom: 8px;
}
.stat-tag {
  display: flex;
  flex-wrap: wrap;
}

.risk-score-value {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.risk-score-wrap {
  position: absolute;
  top: 255px;
}

.risk-score-label {
  font-size: 14px;
}

.sub-types-owner {
  /*margin-right: 24px;*/
  text-align: left;
}


.types-list-wrap {
  border-top: 1px solid var(--dark-grey-d-3);
  padding-top: 8px;
}

.table__loader {
  position: absolute;
  top: calc(50% + 40px);
  left: 50%;

  transform: translate(-50%, -50%);
}

.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}

.transactions-list {
  width: 160px;
}

.transactions-list .vs__dropdown-toggle {
  height: 40px !important;
  background: #fff;
  border: none;
}

.transactions-list-select__pagination {
  width: 150px;
  background: #fff !important;
}

.transactions-list-select__pagination .vs__dropdown-toggle{
  background: #fff !important;
  border: 1px solid #f5f5f5 !important;
  height: 35px;
}

.stat-item__half {
  width: 50%;
}

.hidden__tags {
  display: none;
}

.all-tags-btn {
  border: 1px solid var(--soft-blue);
  display: inline-block;
  color: var(--soft-blue);
  padding: 2px 5px;
  min-width: 24px;
  height: 24px;
  text-align: center;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
}

.testPie {
  /*min-width: 400px;*/
  width: max-content;
  position: fixed;
  padding: 16px;
  z-index: 10;
  background-color: var(--white);
  box-shadow: 4px 5px 8px 0 rgba(211, 211, 211, 0.64);
  border-radius: 3px;
  /*border: solid 1px var(--soft-blue);*/
  transform: translateX(-100%);
}

.sub-types-amount {
  white-space: nowrap;
  display: block;
  min-width: 140px;
}

.switch__wrap input[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch__wrap label {
  cursor: pointer;
  text-indent: -9999px;
  width: 30px;
  height: 10px;
  background: #3030D628;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switch__wrap label:after {
  content: '';
  position: absolute;
  top: -3px;
  left: 0;
  width: 16px;
  height: 16px;
  background: var(--reflex-bluet);
  border-radius: 90px;
  transition: 0.3s;
}

.switch__wrap input:checked + label {
  background: #3030D628;
}

.switch__wrap input:checked + label:after {
  left: 100%;
  transform: translateX(-100%);
}

.switch__wrap label:active:after {
  width: 50px;
}

.switch__wrap {
  margin-bottom: 3px;
}

.hatching {
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0) 49.9%, var(--dark-grey-d-3) 49.9%, var(--dark-grey-d-3) 60%, rgba(0, 0, 0, 0) 80%) fixed, linear-gradient(-45deg, var(--dark-grey-d-3) 16%, rgba(0, 0, 0, 0) 25%) fixed;
  background-size: 0.5em 0.5em;
  opacity: 0.6;
}

.source__action-block {
  position: fixed;
  bottom: 0;
  width: 640px;
  box-shadow: 0 0 8px 0 rgba(211, 211, 211, 0.64);
  padding: 12px 24px 24px;
  background: var(--white);
}

@keyframes skeleton-animation
{

  0%
  {
    right: 100%;
  }

  50%
  {
    right: 0;
  }

  100%
  {
    right: 100%;
  }

}

.tab-loading:before
{
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 128px;
  height: 40px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #FFF, rgba(255, 255, 255, 0));
  content: "";
  animation: skeleton-animation 1.25s infinite;
}

.filters-control {
  display: flex;
  align-items: center;
  background: var(--pale-grey);
  color: var(--dark-grey-6-e);
  padding: 4px 12px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  border: 1px solid #ccc;
  height: 34px;
}
.txs-list-filter-wrapper {
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  background: var(--white);
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(110, 110, 110, 0.25);
  width: 100%;
  z-index: 100;
}
.filter-filled-sign::before {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  height: 8px;
  width: 8px;
  background-color: var(--scarlet-red);
  border-radius: 50%;
}

@media (max-width: 767px) {
  .analytics-tabs {
    justify-content: unset;
  }
  .stat-item {
    width: 50%;
  }
  .source__action-block {
    position: static;
    width: 100%;
  }
  .risk-score-wrap {
    top: 130px;
  }
}

@media (max-width: 360px) {
  .stat-item {
    width: 100%;
  }
}


.new-direction-filter {
  flex: 1 0 auto;
  margin-bottom: 16px;
}
.new-direction-filter .gl-select-button-wrapper {
  width: 100%;
}
.new-direction-filter::v-deep .gl-select-button__item {
  flex: 1 0 auto;
}


.new-tx-filter {
  display: flex;
  gap: 8px;
  position: relative;
}

.new-datepicker {
  width: auto !important;
  height: 32px;
}
.new-datepicker::v-deep .mx-input::placeholder {
  color: var(--space-cadet);
}
.new-datepicker::v-deep .mx-icon-calendar,
.new-datepicker::v-deep .mx-icon-clear {
  display: none;
}

.new-datepicker__input {
  min-width: 125px;
  height: 32px;
  padding: 6px 36px;
  background-color: var(--cotton-grey-f-5);
  border-radius: 6px;
  position: relative;
  cursor: pointer;
}
.new-datepicker__input-calendar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
.new-datepicker__input-arrow-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
.new-datepicker__input-close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}
.new-datepicker svg {
  width: 100% !important;
  height: 100% !important;
}
.new-datepicker__input-content {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--space-cadet);
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.new-datepicker__input.new-datepicker__input--value {
  background-color: var(--profile-card-background);
}
.new-datepicker__input--value .new-datepicker__input-content {
  color: var(--datepicker-blue);
}



.new-amount__input {
  min-width: 105px;
  height: 32px;
  padding: 6px 36px 6px 10px;
  background-color: var(--cotton-grey-f-5);
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--space-cadet);
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.new-amount__input-amount {
  display: flex;
  align-items: center;
}
.new-amount__input-amount-divider {
  padding: 0 4px;
}
.new-amount__input--value {
  background-color: var(--profile-card-background);
  color: var(--datepicker-blue);
}


.new-transactions-block {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 20px 16px;
  margin-bottom: 24px;
  padding: 16px;
  background-color: var(--menu-background);
  border-radius: 12px;
}
.new-transactions-block .all-types__info-block {
  width: auto !important;
  margin-bottom: 0 !important;
  padding-right: 0 !important;
}
.all-types-info .new-transactions-block .all-types__info-block-header {
  padding-top: 0 !important;
}
</style>
