<template>
  <div class="toolbar gap-3 pr-60 m-pr-0">
    <div class="flex mr-1 search-block">
      <gl-search-box
        v-model="search"
        button-text="find"
        dark-clear
        :disabled="isUnknowMode"
        full
        :grey="isMobile ? true : false"
        independent
        placeholder="Find on graph"
        @clear="clearAll"
        @search="$emit('search', $event, false)"
      />
    </div>
    <div
      v-if="!$can('use', 'eth')"
      class="flex align-center toolbar__bottom-right-checkbox"
    >
      <gl-checkbox
        v-model="semiAutomaticMode"
        label="Semi-automatic"
        @input="changeSemiAutomaticMode"
      />
    </div>
  </div>
</template>

<script>
// Components
import GlSearchBox from '@/components/gl-search-box'
import GlMenuItem from '@/components/gl-menu-item'
import GlIcon from '@/components/gl-icon'
import GlCheckbox from '@/components/gl-checkbox'
// Vuex
import { mapState } from 'vuex'
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlSearchBox,
    GlMenuItem,
    GlCheckbox,
    GlIcon
  },
  mixins: [deviceWidthMixin],
  data() {
    return {
      semiAutomaticMode: false,
      search: ''
    }
  },
  computed: {
    ...mapState('analytics', ['isHash', 'isAddress']),
    modeExplain() {
      return 'Indicator of the node click-to-explore mode. Search for a transaction ID so by clicking on the node, you reveal the next spend of the output. Search for a wallet address to investigate all of its transactions.'
    },
    isUnknowMode() {
      return !this.isHash && !this.isAddress
    },
  },
  methods: {
    clearAll() {
      this.search = '';
      this.$emit('search', this.search)
    },
    changeSemiAutomaticMode() {
      this.$emit('set-semi-automatic', this.semiAutomaticMode)
    },
  }
}
</script>

<style scoped>
.search-block {
  width: 100%;
  max-width: 260px;
}
.toolbar__bottom-right-checkbox {
  height: 100%;
  padding: 0 12px;
  background-color: #fff;
  border-radius: 3px;
  font-size: 14px;
}
.toolbar__bottom-right-checkbox::v-deep .gl-checkbox {
  display: flex;
  align-items: center;
}
.toolbar__bottom-right-checkbox::v-deep .gl-checkbox__input:checked + .gl-checkbox__check:after {
    width: 8px;
    height: 8px;
}

@media (max-width: 767px) {
  .search-block {
    max-width: unset;
  }
}
</style>
