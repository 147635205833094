<template>
  <div class="menu-dropdown-items-wrapper">
    <gl-menu-item
      icon="import"
      :icon-height="24"
      :icon-width="24"
      label="Import"
      @click="$emit('open-file-input')"
      class="menu-dropdown-items__item"
    />
    <div class="menu-dropdown-items__item">
      <ul id="demo">
        <TreeItem
          class="item"
          :disabled="isUnknowMode"
          :item="exportList"
          @edge="$emit('exports', $event)"
        />
      </ul>
    </div>
    <gl-menu-item
      :disabled="isUnknowMode"
      icon="save"
      :icon-height="24"
      :icon-width="24"
      label="Save Case"
      @click="$emit('export-case-handler', $event)"
      class="menu-dropdown-items__item"
    />
    <gl-menu-item
      :disabled="isUnknowMode || !caseData"
      icon="save-as"
      :icon-height="24"
      :icon-width="24"
      label="Save Case as"
      @click="$emit('save-as', $event)"
      class="menu-dropdown-items__item"
    />
  </div>
</template>

<script>
// Components
import GlMenuItem from "@/components/gl-menu-item";
import TreeItem from "@/components/gl-tree-item";

export default {
  components: {
    GlMenuItem,
    TreeItem
  },
  props: {
    isUnknowMode: {
      type: Boolean,
      default: true,
    },
    exportList: {
      type: Object,
      default: () => ({})
    },
    caseData: {
      type: Object,
      default: () => ({})
    },

  },
}
</script>
<style scoped>
  .menu-dropdown-items-wrapper {
    padding: 6px;
  }
  .menu-dropdown-items__item + .menu-dropdown-items__item {
    margin-top: 16px;
  }
</style>