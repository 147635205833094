<template>
  <div
    v-if="value"
    v-click-outside="closeMenu"
    v-on-clickaway="closeMenu"
    class="gl-context-menu"
    oncontextmenu="return false"
    :style="contextStyle"
  >
    <div class="bold-600 ml-2 mb-2 fs-14 main-text">
      Address Actions
    </div>
    <gl-menu-item
      icon="copy"
      :icon-height="24"
      :icon-width="24"
      label="Copy address"
      :main-text-color="isDesktop ? false : true"
      @click="copyAddress"
    />
    <a
      class="gl-menu-item"
      :href="explorerLink"
      target="_blank"
    >
      <gl-icon
        class="gl-menu-item__icon"
        :height="24"
        name="search"
        :width="24"
      />
      <span class="gl-menu-item__label gl-link">
        {{ explorerLabel }}
      </span>
    </a>
    <gl-menu-item
      icon="external-link"
      :icon-height="24"
      :icon-width="24"
      label="Check the Address in the New Tab"
      :main-text-color="isDesktop ? false : true"
      @click="openInNewTab"
    />
    <gl-menu-item
      icon="customize"
      label="Customize"
      :main-text-color="isDesktop ? false : true"
      @click="openCustomizeModal"
    />
    <gl-menu-item
      icon="incomes"
      :icon-height="24"
      :icon-width="24"
      label="Select incomes"
      :main-text-color="isDesktop ? false : true"
      @click="selectIncomers"
    />
    <gl-menu-item
      icon="outcomes"
      :icon-height="24"
      :icon-width="24"
      label="Select outcomes"
      :main-text-color="isDesktop ? false : true"
      @click="selectOutgoers"
    />
    <gl-menu-item
      icon="hide"
      :icon-height="24"
      :icon-width="24"
      label="Hide"
      :main-text-color="isDesktop ? false : true"
      @click="hideNodes"
    />
  </div>
</template>

<script>
import { mapState } from "vuex"
import { mixin as clickaway } from 'vue-clickaway'
import GlMenuItem from '@/components/gl-menu-item'
import GlIcon from '@/components/gl-icon'
import { linkDataFormatter } from "@/pages/analytics/context-menus/explorerLinksFormatter";
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
// Directives
import vClickOutside from 'v-click-outside'
// Utils
import { copyText } from '@/utils/copy-text'

export default {
  components: {
    GlMenuItem,
    GlIcon,
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [clickaway, deviceWidthMixin],
  props: {
    position: {
      type: Object,
      default: () => ({ x: 0, y: 0 }),
    },
    selectedNode: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contextStyle: {
        left: `${ this.position.x }px`,
        top: `${ this.position.y }px`,
      },
    }
  },
  computed: {
    ...mapState('analytics', ['isAddress', 'coinType']),
    explorerLink() {
      const { link } = this.linkDataFormatter(this.selectedNode.id, this.coinType, 'address')
      return link
    },
    explorerLabel() {
      const { label } = this.linkDataFormatter(this.selectedNode.id, this.coinType, 'address')
      return label
    },
  },
  watch: {
    position: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          const menu = this.$el
          const { x, y } = this.position
          const availableWidth = menu.parentNode.clientWidth - x
          const availableHeight = menu.parentNode.clientHeight - y
          this.contextStyle = {
            left: `${ menu.clientWidth > availableWidth ? x - menu.clientWidth : x }px`,
            top: `${ menu.clientHeight > availableHeight ? y - menu.clientHeight : y }px`,
          }
          if (this.isMobile) this.contextStyle = { left: `20px`, top: `120px`}
          if (menu.parentNode.clientHeight < 600) this.contextStyle = { left: `20px`, top: `60px`}
        })
      },
    },
  },
  methods: {
    linkDataFormatter,
    copyText,
    closeMenu() {
      this.$emit('input', false)
    },
    async copyAddress() {
      if(this.isMobile) this.copyText(this.selectedNode.id) 
      await navigator.clipboard.writeText(this.selectedNode.id).then(() => {
        this.closeMenu()
        this.$toasted.global.success({ message: 'Copied!' })
      }).catch((err) => {
        console.log(err)
      })
    },
    openInNewTab() {
      const { href } = this.$router.resolve({ name: 'analytics', query: { address: this.selectedNode.id, type: this.coinType } })
      window.open(href, '_blank')
      this.closeMenu()
    },
    selectIncomers() {
      this.$emit('deselect-node', `#${ this.selectedNode.id }`)
      this.$emit('select-incomers', `#${ this.selectedNode.id }`)
      this.closeMenu()
    },
    selectOutgoers() {
      this.$emit('deselect-node', `#${ this.selectedNode.id }`)
      this.$emit('select-outgoers', `#${ this.selectedNode.id }`)
      this.closeMenu()
    },
    openCustomizeModal() {
      this.$emit('customize-node')
      this.closeMenu()
    },
    hideNodes() {
      this.$emit('hide-nodes', ':selected')
      this.closeMenu()
    },
  },
}
</script>
